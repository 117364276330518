import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';

const DeliveryOption = ({ deliveryOption, onButtonClick }) => {
  const { t } = useTranslation();
  const { nameTranslations } = useDeliveryOptionsTranslations();
  const deliveryOptionName = nameTranslations[deliveryOption?.code];

  return (
    <div
      data-test="delivery-option"
      className="flex flex-col gap-4 divide-y divide-grey-200 rounded-md bg-white p-4 shadow-elevation-100 sm:flex-row sm:gap-8 sm:divide-y-0 sm:py-5"
    >
      <div className="flex flex-1 flex-col gap-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="flex items-center justify-between sm:flex-1">
          <div className="self-start font-medium">
            {deliveryOptionName || deliveryOption?.code}
          </div>
          <div
            className={cn(
              'font-medium',
              deliveryOption?.enabled && 'text-ui-green-dark',
            )}
          >
            {deliveryOption?.enabled ? t('Enabled') : t('Disabled')}
          </div>
        </div>

        {deliveryOption?.enabled && deliveryOption?.requiredConsent && (
          <div className="text-grey-700" data-test="customer-consent-required">
            ({t('Customer consent required')})
          </div>
        )}
      </div>
      <div className="pt-4 sm:pt-0">
        <Button
          data-test="open-delivery-option-modal"
          className="w-full sm:w-auto"
          size="s"
          variant="outlineBlack"
          text={t('Configure')}
          onClick={() => onButtonClick(deliveryOption.id)}
        />
      </div>
    </div>
  );
};

DeliveryOption.propTypes = {
  deliveryOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    requiredConsent: PropTypes.bool.isRequired,
    requiredNote: PropTypes.bool,
    enabled: PropTypes.bool,
  }).isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default DeliveryOption;
