import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Breakpoint from '../../../enums/Breakpoint';
import UserRole from '../../../enums/UserRole';
import TourHelper from '../../../helpers/TourHelper';
import { useUser } from '../../../providers/UserProvider';
import TourAssignmentModal from '../../tours/TourAssignmentModal';
import DriverButton from '../DriverButton';
import UserPopover from '../UserPopover';

const OrganisationInfo = (props) => {
  const { isDesktop, publicName, shortCode } = props;
  return (
    <div
      className={cn(
        'flex items-center gap-2 rounded-md border border-grey-500 bg-grey-200 px-3',
        isDesktop ? 'py-[5px]' : 'py-[7px]',
      )}
      data-test="organisation-info"
    >
      <Icon className={cn(isDesktop ? 'h-5 w-5' : 'h-6 w-6')} icon="domain" />
      <div className="text-sm font-medium text-grey-900">
        {shortCode}
        {publicName && <span> ({publicName})</span>}
      </div>
    </div>
  );
};

OrganisationInfo.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  publicName: PropTypes.string,
  shortCode: PropTypes.string,
};

OrganisationInfo.defaultProps = {
  publicName: undefined,
  shortCode: undefined,
};

const TourAssignment = ({ tour }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useUser();
  const userRole = user.role;

  const isAssignEnabled =
    (userRole === UserRole.Admin ||
      userRole === UserRole.OperationsManager ||
      userRole === UserRole.HubManager) &&
    TourHelper.isAssignmentEnabled(tour);

  const { isSubcarrierUser } = useUser();

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  if (tour.driver) {
    return (
      <UserPopover user={tour.driver} title={t('Driver Contact')}>
        <DriverButton
          className="w-full"
          firstName={tour?.driver?.firstName}
          lastName={tour?.driver?.lastName}
          size={isDesktop ? 's' : 'm'}
        />
      </UserPopover>
    );
  }

  if (tour.subcarrier && !isSubcarrierUser) {
    return (
      <OrganisationInfo
        isDesktop={isDesktop}
        publicName={tour.subcarrier?.publicName}
        shortCode={tour.subcarrier?.shortCode}
      />
    );
  }

  return (
    <>
      <TourAssignmentModal
        isOpen={isModalOpen}
        tour={tour}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
      <Button
        className="w-fit"
        data-test="assign-tour"
        disabled={!isAssignEnabled}
        icon="plus"
        iconSize={isDesktop ? 's' : 'm'}
        size={isDesktop ? 's' : 'm'}
        text={t('Assign')}
        variant="outlineRed"
        onClick={() => setIsModalOpen(true)}
      />
    </>
  );
};

TourAssignment.propTypes = {
  tour: PropTypes.shape({
    carrier: PropTypes.shape({
      publicName: PropTypes.string,
      shortCode: PropTypes.string,
    }),
    driver: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    status: PropTypes.string,
    subcarrier: PropTypes.shape({
      publicName: PropTypes.string,
      shortCode: PropTypes.string,
    }),
  }),
};

TourAssignment.defaultProps = {
  tour: undefined,
};

export default TourAssignment;
