import { useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ActivityItem from '../../../components/ActivityItem';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import { SocketHandler } from '../../../lib/api/hooks/useSocket';

const TourActivity = ({ tourId }) => {
  const { t } = useTranslation();

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();

  const url = `/activity/tours/${tourId}`;

  const { data: tourActivityQueryData } = useQuery(
    [url],
    async () => {
      const response = await fetch(url, {
        method: 'GET',
      });
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
    },
  );

  const activities = tourActivityQueryData?.data || [];

  const onActivitySocketEvent = useCallback(
    (event) => {
      if (
        event?.data?.order?.id === tourId &&
        (event?.type === 'updated' || event?.type === 'created')
      ) {
        queryClient.invalidateQueries([url]);
      }
    },
    [queryClient, tourId, url],
  );

  return (
    <>
      <SocketHandler entity="activity" onEvent={onActivitySocketEvent} />

      {activities.length === 0 ? (
        <div
          className="flex items-center rounded-md bg-grey-100 p-4 font-normal text-primary-dark"
          data-test="no-activity-for-tour"
        >
          {t('No recorded activity for this Tour')}
        </div>
      ) : (
        <div className="flex flex-col divide-y divide-grey-300">
          {activities.map((activityItem) => (
            <ActivityItem activityItem={activityItem} key={activityItem.id} />
          ))}
        </div>
      )}
    </>
  );
};

TourActivity.propTypes = {
  tourId: PropTypes.string,
};

TourActivity.defaultProps = {
  tourId: undefined,
};

export default TourActivity;
