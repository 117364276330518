import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import FormInput from '../../../components/FormInput';
import ShortcodeInput from '../../shared/ShortcodeInput';

const SubcarrierForm = (props) => {
  const {
    isEditMode,
    isSubmitting,
    name,
    onCancel,
    onIsDirtyChange,
    onSubmit,
    shortCode,
  } = props;

  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      name,
      shortCode,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    formState: { isDirty },
    handleSubmit,
  } = methods;

  const onFormSubmit = (values) => {
    onSubmit(values);
  };

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-8"
        noValidate
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <div>
          <h3 className="grey-200 mb-5 text-base font-semibold">
            {t('Basic Information')}
          </h3>
          <div className="flex flex-col gap-4">
            <FormInput
              id="name"
              label={t('Name')}
              name="name"
              placeholder={t('Enter name')}
              required
            />
          </div>
        </div>
        <div>
          <h3 className="grey-200 mb-5 text-base font-semibold">
            {t('Setup')}
          </h3>
          <div className="flex flex-col gap-4">
            <ShortcodeInput
              disabled={isEditMode}
              disclaimerMessage={t(
                'Shortcode should be 3 to 5 characters long.',
              )}
              id="shortCode"
              label={t('Shortcode')}
              maxLength={{
                message: t("Shortcode can't be more than 5 characters long"),
                value: 5,
              }}
              minLength={{
                message: t('Shortcode must be at least 3 characters long'),
                value: 3,
              }}
              name="shortCode"
              pattern={{
                message: t(
                  'Shortcode can only contain uppercase letters and numbers',
                ),
                value: /^[A-Z0-9]+$/,
              }}
              placeholder={t('Enter shortcode')}
              required
            />
          </div>
        </div>
        <hr className="text-grey-200" />
        <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
          <Button
            className="order-last sm:order-none sm:flex-1"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onCancel}
          />
          <Button
            className="sm:flex-1"
            data-test="modal-button-action"
            disabled={isSubmitting}
            text={isEditMode ? t('Save Changes') : t('Create Subcarrier')}
            type="submit"
            variant="solidBlue"
          />
        </div>
      </form>
    </FormProvider>
  );
};

SubcarrierForm.propTypes = {
  isEditMode: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  name: PropTypes.string,
  onCancel: PropTypes.func,
  onIsDirtyChange: PropTypes.func,
  onSubmit: PropTypes.func,
  shortCode: PropTypes.string,
};

SubcarrierForm.defaultProps = {
  isEditMode: false,
  isSubmitting: false,
  name: '',
  onCancel: () => {},
  onIsDirtyChange: () => {},
  onSubmit: () => {},
  shortCode: '',
};

export default SubcarrierForm;
