import { useTranslation } from 'react-i18next';

import useFailureReasonTranslations from '../../../../hooks/useFailureReasonTranslations';
import eventType from '../eventType';

const FailureReasonField = (props) => {
  const { event } = props;
  const { t } = useTranslation();

  const failureReasonTranslations = useFailureReasonTranslations();

  return (
    <div>
      <span>{t('Failure reason')}: </span>
      <span className="font-medium">
        {failureReasonTranslations[event.failureReason]}{' '}
        {event.note && `(${event.note})`}
      </span>
    </div>
  );
};

FailureReasonField.propTypes = {
  event: eventType.isRequired,
};

export default FailureReasonField;
