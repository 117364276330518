import cn from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import useUnitsPageSearchParams from '../../../hooks/useUnitsPageSearchParams';
import Resource from '../../../lib/api/Resource';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import RoutingCycleInfo from '../../shared/RoutingCycleInfo';
import TasksViewToggle from '../../shared/TasksViewToggle';
import UnitBulkEdit from '../UnitBulkEdit';
import UnitsSearch from '../UnitsSearch';
import UnitsTable from '../UnitsTable';

const UnitsPageDesktop = () => {
  const { t } = useTranslation();
  const {
    allParams,
    dateQuery,
    searchByQuery,
    searchParams,
    searchQuery,
    setSearchParams,
    unitStatusQuery,
  } = useUnitsPageSearchParams();

  const { isSelectionVisible, setIsSelectionDisabled } = useListSelection();

  const isBulkFiltersApplied = Boolean(dateQuery) && Boolean(unitStatusQuery);

  useEffect(() => {
    if (isBulkFiltersApplied) {
      setIsSelectionDisabled(false);
    } else {
      setIsSelectionDisabled(true);
    }
  }, [isBulkFiltersApplied, setIsSelectionDisabled]);

  return (
    <Page className="max-h-screen">
      <Titlebar
        variant="small"
        menu={
          <div className={cn(isSelectionVisible && 'flex gap-4')}>
            <div className="flex flex-col lg:flex-row gap-6">
              <UnitsSearch
                searchBy={searchByQuery}
                searchValue={searchQuery}
                onSearch={(newSearchValue) => {
                  if (newSearchValue === searchQuery) {
                    return;
                  }
                  searchParams.set('page', '1');
                  if (!newSearchValue) {
                    searchParams.delete('search');
                  } else {
                    searchParams.set('search', newSearchValue?.trim());
                    searchParams.set('searchBy', searchByQuery);
                  }
                  setSearchParams(searchParams);
                }}
                onSearchByChange={(newSearchBy) => {
                  searchParams.set('page', '1');
                  searchParams.set('searchBy', newSearchBy);
                  setSearchParams(searchParams);
                }}
              />
              {!isSelectionVisible && <RoutingCycleInfo />}
            </div>

            <div className="flex-1">
              {isSelectionVisible && (
                <UnitBulkEdit isBulkFiltersApplied={isBulkFiltersApplied} />
              )}
            </div>
          </div>
        }
        textPrimary={t('Unit view')}
        titleRightContent={<TasksViewToggle />}
      />

      <Page.Content variant="wide">
        <div className="flex flex-col min-h-0">
          <Resource
            paginationStickyBottom
            itemsPerPage={100}
            showPageSizePicker={false}
            params={allParams}
            resourceUrl="/units"
            socketEntityName="unit"
          >
            <UnitsTable />
          </Resource>
        </div>
      </Page.Content>
    </Page>
  );
};

export default UnitsPageDesktop;
