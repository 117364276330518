import eventType from '../eventType';
import ExecutionField from './ExecutionField';
import StatusField from './StatusField';
import UserField from './UserField';

const ImportEvent = (props) => {
  const { event } = props;
  return (
    <>
      <StatusField event={event} />
      <ExecutionField event={event} />
      <UserField event={event} />
    </>
  );
};

ImportEvent.propTypes = {
  event: eventType.isRequired,
};

export default ImportEvent;
