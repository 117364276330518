import cn from 'classnames';
import PropTypes from 'prop-types';

import ActivityType from '../../enums/ActivityType';
import Icon from '../Icon';

const activityTypesMap = {
  [ActivityType.TourRequestManualScanning]: {
    bg: 'bg-white',
    color: 'text-ui-warning-orange',
    icon: 'codeScan',
  },
  [ActivityType.CheckIn]: {
    bg: 'bg-[rgba(39,174,96,0.08)]',
    color: 'text-ui-green-dark',
    icon: 'startTime',
  },
  [ActivityType.CheckOut]: {
    bg: 'bg-[rgba(39,174,96,0.08)]',
    color: 'text-ui-green-dark',
    icon: 'flagOutlined',
  },
  [ActivityType.AutoCheckOut]: {
    bg: 'bg-[rgba(39,174,96,0.08)]',
    color: 'text-ui-green-dark',
    icon: 'flagOutlined',
  },
  [ActivityType.ManualCheckOut]: {
    bg: 'bg-[rgba(39,174,96,0.08)]',
    color: 'text-ui-green-dark',
    icon: 'flagOutlined',
  },
  [ActivityType.LateCheckIn]: {
    bg: 'bg-[rgba(250,130,49,0.08)]',
    color: 'text-ui-warning-orange',
    icon: 'hourglassEmpty',
  },
  [ActivityType.ReportDelay]: {
    bg: 'bg-[rgba(250,130,49,0.08)]',
    color: 'text-ui-warning-orange',
    icon: 'clock',
  },
  [ActivityType.TourCanceledAdmin]: {
    bg: 'bg-ui-red-light',
    color: 'text-ui-red',
    icon: 'xCircleOutlined',
  },
  [ActivityType.TourCompletedAdmin]: {
    bg: 'bg-[rgba(39,174,96,0.08)]',
    color: 'text-ui-green-dark',
    icon: 'checkmarkSemicircle',
  },
  [ActivityType.TourReport]: {
    bg: 'bg-[rgba(250,130,49,0.08)]',
    color: 'text-ui-warning-orange',
    icon: 'report',
  },
};

const ActivityIcon = (props) => {
  const { activityType, isRecent } = props;

  const { bg, color, icon } = activityTypesMap[activityType];

  return (
    <div
      className={cn(
        'flex h-8 w-8 items-center justify-center rounded-full',
        isRecent ? 'bg-[rgba(108,117,125,0.08)]' : bg,
      )}
    >
      <Icon
        className={cn('h-5 w-5', isRecent ? 'text-grey-700' : color)}
        icon={icon}
      />
    </div>
  );
};

ActivityIcon.propTypes = {
  activityType: PropTypes.oneOf(Object.values(ActivityType)).isRequired,
  isRecent: PropTypes.bool,
};

ActivityIcon.defaultProps = {
  isRecent: true,
};

export default ActivityIcon;
