import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import HubForm from '../../features/hubs/HubForm';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const EditHub = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { toastSuccess } = useCustomToast();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const {
    data: hubQueryData,
    error,
    isError,
    isLoading,
  } = useQuery([`/hubs/${id}`], async () => {
    const response = await fetch(`/hubs/${id}`, {
      method: 'GET',
    });
    return response.json();
  });

  const hub = hubQueryData?.data;

  const hubUpdateMutation = useMutation(
    async (values) => {
      const response = await fetch(`/hubs/${id}`, {
        body: values,
        method: 'PATCH',
      });
      return response.json();
    },
    {
      onError: toastFetchError,
      onSuccess: () => {
        toastSuccess(t('Hub data updated.'));
      },
    },
  );

  const shouldBlock = useMemo(() => {
    if (hubUpdateMutation.isSuccess) {
      return false;
    }
    return isFormDirty;
  }, [isFormDirty, hubUpdateMutation.isSuccess]);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (hubUpdateMutation.isSuccess) {
      navigate(`/hubs/${id}`);
    }
  }, [navigate, id, hubUpdateMutation.isSuccess]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <CancelPrompt
        title={t('Cancel Editing Hub?')}
        isOpen={blocker.state === 'blocked'}
        onClose={() => blocker.reset()}
        onExitClick={() => blocker.proceed()}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="edit" />}
          textPrimary={t('Edit Hub')}
          textSecondary={hub?.name}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate(`/hubs/${id}`)}
            />
          }
          isLoading={isLoading}
        />

        <Page.Content centerContent variant="grey">
          <div className="w-full max-w-[480px]">
            <Card className="px-4 py-5 sm:p-8 sm:pt-6">
              {isLoading ? (
                <div className="grid gap-8">
                  <Skeleton height={110} />
                  <Skeleton height={110} />
                  <Skeleton height={110} />
                </div>
              ) : (
                <HubForm
                  isLoadingData={isLoading}
                  address={hub?.address}
                  clientId={hub?.client?.id}
                  email={hub?.email || undefined}
                  isEditMode
                  isSubmitting={hubUpdateMutation.isLoading}
                  name={hub?.name}
                  postCodes={hub?.postCodes}
                  shortCode={hub?.shortCode}
                  type={hub?.type}
                  onCancel={() => navigate(`/hubs/${id}`)}
                  onIsDirtyChange={setIsFormDirty}
                  onSubmit={hubUpdateMutation.mutate}
                  isBlocked={blocker.state === 'blocked'}
                />
              )}
            </Card>
          </div>
        </Page.Content>
      </Page>
    </>
  );
};

export default EditHub;
