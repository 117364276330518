import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Icon from '../../../components/Icon';

const sizesMap = {
  m: 'h-10',
  s: 'h-8',
};

const DriverButton = forwardRef((props, ref) => {
  const { className, firstName, lastName, onClick, size } = props;

  const fullName = `${firstName} ${lastName}`;

  return (
    <button
      className={cn(
        'flex w-full flex-1 flex-row items-center justify-between gap-1 rounded-md border border-grey-500 bg-white px-2.5 py-[7px] outline-none',
        sizesMap[size],
        className,
      )}
      ref={ref}
      type="button"
      onClick={onClick}
    >
      <div className="flex min-w-0 flex-1 flex-row items-center gap-2">
        <div
          className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-medium"
          data-test="driver-button"
        >
          {fullName}
        </div>
      </div>
      <div className="flex">
        <Icon className="h-4 w-4 text-grey-900" icon="call" />
      </div>
    </button>
  );
});

export default DriverButton;

DriverButton.propTypes = {
  className: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.keys(sizesMap)),
};

DriverButton.defaultProps = {
  className: '',
  firstName: '',
  lastName: '',
  onClick: () => {},
  size: 'm',
};
