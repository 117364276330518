import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, Fragment } from 'react';

import Icon from '../Icon';
import RadioButton from '../RadioButton';

const RadioGroup = forwardRef((props, ref) => {
  const { disabled, divider, name, onChange, options, value } = props;

  return (
    <fieldset className={cn('flex flex-col', divider && 'gap-4')}>
      {options.map((option, index) => (
        <Fragment key={option.value}>
          <div>
            <RadioButton
              checked={option.value === value}
              disabled={option.disabled || disabled}
              id={`${name}-${option.value}`}
              label={option.label}
              name={name}
              ref={ref}
              value={option.value}
              onChange={onChange}
            />
            {option.description && (
              <div className="flex mt-1 items-center gap-2">
                <Icon className="h-4 w-4 text-ui-info-blue" icon="infoFilled" />

                <p className="text-sm text-grey-700">{option.description}</p>
              </div>
            )}
          </div>
          {divider && index !== options.length - 1 && (
            <hr className="border-grey-200" />
          )}
        </Fragment>
      ))}
    </fieldset>
  );
});

RadioGroup.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  divider: PropTypes.bool,
};

RadioGroup.defaultProps = {
  disabled: false,
  onChange: () => {},
  options: [],
  value: undefined,
  divider: false,
};

export default RadioGroup;
