import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Drawer from '../../../components/Drawer';
import parseSearchParams from '../../../helpers/parseSearchParams';
import useTasksPageSearchParams from '../../../hooks/useTasksPageSearchParams';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import ClientSelect from '../../shared/ClientSelect';
import HubSelect from '../../shared/HubSelect';
import ShiftsSelect from '../../shared/ShiftsSelect';
import TasksSearch from '../TasksSearch';
import TasksStatusSelect from '../TasksStatusSelect';
import TasksTypeSelect from '../TasksTypeSelect';

const TasksFiltersDrawer = (props) => {
  const { isOpen, onClose } = props;
  const {
    currentSortType,
    searchParams: currentSearchParams,
    setSearchParams,
  } = useTasksPageSearchParams();
  const { selectedItems } = useListSelection();
  const isSelectionActive = selectedItems.length > 0;
  const prevIsOpen = usePrevious(isOpen);
  const { t } = useTranslation();
  const [params, setParams] = useState(parseSearchParams(currentSearchParams));

  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      setParams(parseSearchParams(currentSearchParams));
    }
  }, [currentSearchParams, isOpen, prevIsOpen]);

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      const newParams = { ...params };

      newParams.page = 1;
      if (!queryValue) {
        delete newParams[queryKey];
      } else {
        newParams[queryKey] = queryValue;
      }
      setParams(newParams);
    },
    [params],
  );

  const [searchInputKey, setSearchInputKey] = useState(new Date().toString());

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      header={t('Filters')}
      footer={
        <Button
          data-test="modal-button-action"
          text={t('Save')}
          variant="solidBlue"
          isFullWidth
          onClick={() => {
            const urlParams = new URLSearchParams(params);
            setSearchParams(urlParams);
            onClose();
          }}
        />
      }
      body={
        <div className="flex flex-col gap-4">
          <ClientSelect
            value={params.clientId}
            onChange={(clientId) => {
              onSelectChange('clientId', clientId);
            }}
          />

          <HubSelect
            disabled={isSelectionActive}
            value={params.hubId}
            onChange={(hubId) => {
              onSelectChange('hubId', hubId);
            }}
          />

          <ShiftsSelect
            value={params.shiftNumber}
            onChange={(value) => {
              const newParams = { ...params };
              newParams.page = 1;
              if (!value) {
                delete newParams.shiftNumber;
              } else {
                newParams.shiftNumber = value;
              }

              setParams(newParams);
            }}
          />

          <TasksStatusSelect
            value={params.status}
            onChange={(status) => onSelectChange('status', status)}
          />

          <TasksTypeSelect
            value={params.type}
            onChange={(type) => onSelectChange('type', type)}
          />

          <DatePicker
            dateFormat="dd.MM.yyyy"
            isClearable
            name="date"
            placeholderText={t('Date')}
            required
            value={params.date ? new Date(params.date) : undefined}
            onChange={(date) => {
              const formattedDate = date && moment(date).format('YYYY-MM-DD');
              onSelectChange('date', formattedDate);
            }}
          />

          <TasksSearch
            key={searchInputKey}
            debounceMs={0}
            searchBy={params.searchBy}
            searchValue={params.search}
            onSearch={(newSearchValue) => {
              const newParams = { ...params };
              if (newSearchValue === params.search || !params.searchBy) {
                return;
              }
              newParams.page = 1;
              if (!newSearchValue) {
                delete newParams.search;
              } else {
                newParams.search = newSearchValue?.trim();
                newParams.searchBy = params.searchBy;
              }
              setParams(newParams);
            }}
            onSearchByChange={(newSearchBy) => {
              const newParams = { ...params };
              newParams.page = 1;
              newParams.searchBy = newSearchBy;
              setParams(newParams);
            }}
          />

          <Button
            isFullWidth
            variant="outlineBlue"
            text={t('Clear All')}
            onClick={() => {
              setSearchInputKey(new Date().toString());
              const newParams = {
                page: 1,
                sortType: currentSortType,
                searchBy: params.searchBy,
                search: '',
              };

              setParams(newParams);
            }}
          />
        </div>
      }
    />
  );
};

TasksFiltersDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

TasksFiltersDrawer.defaultProps = {
  isOpen: false,
  onClose: undefined,
};

export default TasksFiltersDrawer;
