import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';

const DeleteMemberConfirmationModal = ({ handleDelete, isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      body={
        <p>
          {t(
            'Note that this action is permanent. Member will no longer exist in the Urbify system.',
          )}
        </p>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Delete')}
            variant="solidRed"
            onClick={() => {
              onClose();
              handleDelete();
            }}
          />
        </div>
      }
      header={
        <div className="flex items-center gap-2">
          <Icon className="h-6 w-6 text-ui-red" icon="delete" />
          <span>{t('Delete Member?')}</span>
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

DeleteMemberConfirmationModal.propTypes = {
  handleDelete: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

DeleteMemberConfirmationModal.defaultProps = {
  handleDelete: () => {},
};

export default DeleteMemberConfirmationModal;
