import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Label from '../../../components/Label';
import OrganisationType from '../../../enums/OrganisationType';

const MemberOrganisationBadge = ({ user }) => {
  const { t } = useTranslation();

  const organisationTypeLabel = {
    [OrganisationType.Carrier]: {
      text: t('Carrier'),
      variant: 'orange',
    },
    [OrganisationType.Superadmin]: {
      text: 'Superadmin',
      variant: 'greyOutline',
    },
  };

  if (user?.subcarrier) {
    return <Label size="s" text={t('Subcarrier')} variant="green" />;
  }
  if (user?.client) {
    return <Label size="s" text={t('Client')} variant="blue" />;
  }

  return (
    <Label
      size="s"
      text={organisationTypeLabel[OrganisationType.Carrier]?.text}
      variant={organisationTypeLabel[OrganisationType.Carrier]?.variant}
    />
  );
};

MemberOrganisationBadge.propTypes = {
  user: PropTypes.shape({
    client: PropTypes.shape({
      id: PropTypes.string,
    }),
    carrier: PropTypes.shape({
      id: PropTypes.string,
    }),
    subcarrier: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

MemberOrganisationBadge.defaultProps = {
  user: undefined,
};

export default MemberOrganisationBadge;
