import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import InputLayout from '../InputLayout';
import Textarea from '../Textarea';

const FormTextarea = (props) => {
  const {
    className,
    disabled,
    id,
    label,
    maxLength,
    name,
    placeholder,
    required,
    showCounter,
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorInput = errors[name] && errors[name].message;

  return (
    <Controller
      disabled={disabled}
      name={name}
      control={control}
      render={({ field: { onChange, value, ...rest } }) => (
        <InputLayout
          error={errorInput}
          id={id}
          label={label}
          required={required}
          inputCounter={
            showCounter && !disabled
              ? `${value?.length || 0} / ${maxLength}`
              : undefined
          }
        >
          <Textarea
            className={className}
            name={name}
            id={id}
            placeholder={placeholder}
            onChange={(e) => onChange(e?.target?.value)}
            maxLength={maxLength}
            {...rest}
          />
        </InputLayout>
      )}
    />
  );
};

FormTextarea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  showCounter: PropTypes.bool,
  maxLength: PropTypes.number,
};

FormTextarea.defaultProps = {
  id: '',
  label: '',
  name: '',
  placeholder: '',
  required: false,
  disabled: false,
  className: '',
  showCounter: false,
  maxLength: 1000,
};

export default FormTextarea;
