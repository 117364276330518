import { useTranslation } from 'react-i18next';

import DeliveryTaskUnitStatus from '../../../../enums/DeliveryTaskUnitStatus';
import useDeliveryUnitStatusTranslations from '../../../../hooks/useDeliveryUnitStatusTranslations';
import eventType from '../eventType';
import ExecutionTypeField from './ExecutionTypeField';
import FailureReasonField from './FailureReasonField';
import NoteField from './NoteField';
import ReturnReasonField from './ReturnReasonField';
import StatusField from './StatusField';
import UserField from './UserField';

const StatusChangeEvent = (props) => {
  const { event, previousEventWithStatus } = props;
  const { t } = useTranslation();

  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();
  const previousStatus = previousEventWithStatus?.status;

  return (
    <>
      <StatusField event={event} />
      {event.status === DeliveryTaskUnitStatus.Delivered && (
        <ExecutionTypeField event={event} />
      )}
      {(event.status === DeliveryTaskUnitStatus.Retry ||
        event.status === DeliveryTaskUnitStatus.Postponed) && (
        <FailureReasonField event={event} />
      )}
      {event.status === DeliveryTaskUnitStatus.ReturnToClient && (
        <ReturnReasonField event={event} />
      )}

      {event.statusNote && <NoteField note={event.statusNote} />}
      {previousStatus && previousStatus !== event.status && (
        <div>
          <span>{t('Previous status')}: </span>
          <span className="font-medium">
            {deliveryUnitStatusTranslations[previousStatus]}
          </span>
        </div>
      )}
      {event.endCustomerNoticeSent && (
        <div>
          {t('Customer notice:')}{' '}
          <span className="font-medium">{t('Sent')}</span>
        </div>
      )}

      <UserField event={event} />
    </>
  );
};

StatusChangeEvent.propTypes = {
  event: eventType.isRequired,
  previousEventWithStatus: eventType,
};

StatusChangeEvent.defaultProps = {
  previousEventWithStatus: undefined,
};

export default StatusChangeEvent;
