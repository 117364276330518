import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import UserRole from '../../../enums/UserRole';
import Allow from '../../../lib/rbac/Allow';
import { useUser } from '../../../providers/UserProvider';

const MemberDetailsTitlebarContent = (props) => {
  const { memberId, memberRole, onDeleteClick } = props;

  const { t } = useTranslation();

  const { user } = useUser();

  const isCurrentUser = user?.id === memberId;

  const renderEditButton = () => (
    <Button
      as={Link}
      className="w-full lg:w-fit"
      data-test="edit-account"
      icon="edit"
      text={t('Edit Member')}
      to={{
        pathname: `/members/${memberId}/edit`,
      }}
      variant="outlineBlue"
    />
  );

  const renderDeleteButton = () => {
    if (isCurrentUser || memberRole === UserRole.Superadmin) {
      return null;
    }

    return (
      <Button
        className="w-full lg:w-fit"
        data-test="delete-account"
        icon="delete"
        text={t('Delete Member')}
        variant="outlineRed"
        onClick={onDeleteClick}
      />
    );
  };

  return (
    <>
      <Allow roles={[UserRole.Superadmin]}>
        {(memberRole === UserRole.Superadmin ||
          memberRole === UserRole.Admin) && (
          <>
            {renderEditButton()}
            {renderDeleteButton()}
          </>
        )}
      </Allow>
      <Allow roles={[UserRole.Admin]}>
        {(memberRole === UserRole.Admin ||
          memberRole === UserRole.OperationsManager ||
          memberRole === UserRole.HubManager ||
          memberRole === UserRole.Crew ||
          memberRole === UserRole.Customer ||
          memberRole === UserRole.Driver) && (
          <>
            {renderEditButton()}
            {renderDeleteButton()}
          </>
        )}
      </Allow>
      <Allow roles={[UserRole.OperationsManager]}>
        {(memberRole === UserRole.OperationsManager ||
          memberRole === UserRole.HubManager ||
          memberRole === UserRole.Crew ||
          memberRole === UserRole.Driver) &&
          renderEditButton()}
      </Allow>
      <Allow roles={[UserRole.HubManager]}>
        {(memberRole === UserRole.HubManager ||
          memberRole === UserRole.Crew ||
          memberRole === UserRole.Driver) &&
          renderEditButton()}
      </Allow>
      <Allow roles={[UserRole.HubManager, UserRole.OperationsManager]}>
        {memberRole === UserRole.Driver && renderDeleteButton()}
      </Allow>
    </>
  );
};

MemberDetailsTitlebarContent.propTypes = {
  memberId: PropTypes.string,
  memberRole: PropTypes.string,
  onDeleteClick: PropTypes.func,
};

MemberDetailsTitlebarContent.defaultProps = {
  memberId: undefined,
  memberRole: '',
  onDeleteClick: undefined,
};

export default MemberDetailsTitlebarContent;
