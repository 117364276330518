import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Button from '../../../components/Button';
import CollapsableCard from '../../../components/CollapsableCard';
import Label from '../../../components/Label';
import RatingImage from '../../../components/RatingImage';
import OrganisationType from '../../../enums/OrganisationType';
import RatingValue from '../../../enums/RatingValue';
import UserRole from '../../../enums/UserRole';
import MemberStatus from '../../organisations/MemberStatus';
import MemberBadge from '../../shared/MemberBadge';

const MemberCard = (props) => {
  const {
    email,
    hubName,
    id,
    name,
    organisationType,
    rating,
    role,
    status,
    subcarrier,
  } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const isAdmin = role === UserRole.Admin;

  return (
    <CollapsableCard
      id={id}
      expandedContent={
        <div className="flex flex-col gap-1">
          {organisationType === OrganisationType.Carrier && (
            <div className="flex flex-col border-t border-grey-200 py-2">
              <span className="mb-1 text-xs text-grey-700">{t('Hub')}</span>
              {isAdmin ? (
                <span className="text-sm font-medium">{t('All')}</span>
              ) : (
                <span
                  className={
                    hubName ? 'text-sm font-medium' : 'text-xs text-grey-500'
                  }
                >
                  {hubName || 'N/A'}
                </span>
              )}
            </div>
          )}
          <div className="flex flex-col border-t border-grey-200 py-2">
            <span className="mb-1 text-xs text-grey-700">{t('E-mail')}</span>
            <span className="break-all text-sm font-medium">{email}</span>
          </div>
          <div className="flex flex-col border-t border-grey-200 py-2">
            <span className="mb-1 text-xs text-grey-700">{t('Status')}</span>
            <span className="text-sm">
              <MemberStatus status={status} />
            </span>
          </div>
          {organisationType === OrganisationType.Carrier && (
            <div className="flex flex-col border-t border-grey-200 py-2">
              <span className="mb-1 text-xs text-grey-700">{t('Rating')}</span>
              {rating ? (
                <span>
                  <RatingImage rating={rating} size="s" />
                </span>
              ) : (
                <span className="text-xs text-grey-500">N/A</span>
              )}
            </div>
          )}
          <div className="flex flex-col border-t border-grey-200 pt-4">
            <Button
              as={Link}
              icon="pageView"
              isFullWidth
              state={{
                backLink: location.pathname + location.search,
              }}
              text={t('View Member Details')}
              to={{
                pathname: `/members/${id}`,
              }}
              variant="outlineBlack"
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-1">
        <span className="text-base font-medium">
          <span className="mr-2">{name}</span>
          {subcarrier && (
            <Label size="xs" text={subcarrier} variant="primary" />
          )}
        </span>
        <MemberBadge memberRole={role} />
      </div>
    </CollapsableCard>
  );
};

MemberCard.propTypes = {
  email: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.node,
  organisationType: PropTypes.oneOf([
    OrganisationType.Carrier,
    OrganisationType.Superadmin,
  ]),
  rating: PropTypes.oneOf([
    RatingValue.Bad,
    RatingValue.NotSoGood,
    RatingValue.Good,
    RatingValue.VeryGood,
    RatingValue.Excellent,
  ]),
  role: PropTypes.string,
  status: PropTypes.string,
  subcarrier: PropTypes.string,
  hubName: PropTypes.string,
};

MemberCard.defaultProps = {
  email: undefined,
  id: undefined,
  name: undefined,
  organisationType: undefined,
  rating: undefined,
  role: undefined,
  status: undefined,
  subcarrier: undefined,
  hubName: undefined,
};

export default MemberCard;
