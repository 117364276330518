import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationForm from '../../features/shared/OrganisationForm';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const EditOrganisation = () => {
  const { toastSuccess } = useCustomToast();
  const navigate = useNavigate();
  const { fetch } = useFetch();
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const redirectTo = location?.state?.backLink
    ? location?.state?.backLink
    : '/organisations';
  const { toastFetchError } = useToastFetchError();

  const [isFormDirty, setIsFormDirty] = useState(false);

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isLoading,
  } = useQuery([`/carriers/${id}`], async () => {
    const response = await fetch(`/carriers/${id}`);
    return response.json();
  });

  const mutation = useMutation(
    async (values) => {
      const body = {
        address: values.address,
        name: values.name,
        publicName: values.publicName,
      };

      const response = await fetch(`/carriers/${id}`, {
        body,
        method: 'PATCH',
      });
      return response.json();
    },
    {
      onError: toastFetchError,
      onSuccess: () => {
        setIsFormDirty(false);
        toastSuccess(t('Organisation data successfully updated.'));
      },
    },
  );

  const shouldBlock = useMemo(() => {
    if (mutation.isSuccess) {
      return false;
    }
    return isFormDirty;
  }, [isFormDirty, mutation.isSuccess]);

  const blocker = useBlocker(shouldBlock);

  const organisation = fetchedOrganisation?.data;

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate(redirectTo, {
        replace: true,
      });
    }
  }, [mutation.isSuccess, navigate, redirectTo]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <CancelPrompt
        title={t('Cancel Editing Organisation?')}
        isOpen={blocker.state === 'blocked'}
        onClose={() => {
          blocker.reset();
        }}
        onExitClick={() => {
          blocker.proceed();
        }}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="edit" />}
          textPrimary={t('Edit Organisation')}
          textSecondary={organisation?.name}
          isLoading={isLoading}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              disabled={mutation.isLoading}
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate(`/organisations/${id}`)}
            />
          }
        />

        <Page.Content variant="grey">
          <Card className="m-auto mt-4 w-full max-w-[480px] px-4 py-5 sm:p-8 sm:pt-6">
            {isLoading ? (
              <div className="grid gap-8">
                <Skeleton height={156} />
                <Skeleton height={110} />
                <Skeleton height={110} />
              </div>
            ) : (
              <OrganisationForm
                errorCode={mutation?.error?.data?.errorCode}
                errors={mutation?.error?.data?.errors}
                isEdit
                isSubmitting={mutation.isLoading}
                organisation={organisation}
                onCancel={() => navigate(`/organisations/${id}`)}
                onIsDirtyChange={setIsFormDirty}
                onSubmit={mutation.mutate}
              />
            )}
          </Card>
        </Page.Content>
      </Page>
    </>
  );
};

export default EditOrganisation;
