import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BlockNavigation from '../../../components/BlockNavigation';
import Button from '../../../components/Button';
import CancelPrompt from '../../../components/CancelPrompt';
import Drawer from '../../../components/Drawer';
import FormDatePicker from '../../../components/FormDatePicker';
import FormInput from '../../../components/FormInput';
import FormPhoneInput from '../../../components/FormPhoneInput';
import InfiniteScrollFormSelect from '../../../components/InfiniteScrollFormSelect';
import Tooltip from '../../../components/Tooltip';
import TaskType from '../../../enums/TaskType';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import UnitsInput from './UnitsInput';

const ManualDataImport = (props) => {
  const { isOpen, onClose: propOnClose } = props;
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      clientId: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      addressNote: '',
      reference: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      endCustomerNote: '',
      date: null,
      units: [
        {
          reference: '',
        },
      ],
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const { formState, getValues, reset } = methods;
  const { isDirty, isValid } = formState;

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();

  const { toastSuccess } = useCustomToast();

  const onClose = () => {
    propOnClose();
    reset();
  };

  const mutation = useMutation(
    async () => {
      const { date, email, phoneNumber, units, ...rest } = getValues();

      const extendedUnits = units.map(({ reference }, index) => ({
        reference,
        number: index + 1,
      }));

      const body = {
        ...rest,
        units: extendedUnits,
        type: TaskType.Delivery,
        date: date?.toISOString(),
        phoneNumber: phoneNumber ? `+${phoneNumber}` : null,
        email: email !== '' ? email : null,
        timeFrom: null,
        timeTo: null,
        country: null,
      };

      const response = await fetch(`/tasks`, {
        method: 'POST',
        body,
      });

      return response.json();
    },
    {
      onError: toastFetchError,
      onSuccess: () => {
        toastSuccess(
          t(`Task {{taskId}} imported.`, {
            taskId: getValues().reference,
          }),
        );
        onClose();
        queryClient.invalidateQueries({
          queryKey: [`/tasks`],
        });
      },
    },
  );

  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const modalOnClose = () => {
    if (isDirty) {
      setIsPromptVisible(true);
      return;
    }
    onClose();
  };

  return (
    <>
      <FormProvider {...methods}>
        <Drawer
          onClose={modalOnClose}
          isOpen={isOpen}
          header={<span>{t('Manual Data Import')}</span>}
          body={
            <div className="flex flex-col gap-8">
              <InfiniteScrollFormSelect
                required
                useLocalSearch
                url="/clients"
                name="clientId"
                placeholder={t('Select Client')}
                label={t('Client')}
                transformOptionFn={(client) => ({
                  label: `${client.publicName} (${client?.name})`,
                  value: client.id,
                })}
              />
              <div className="flex flex-col gap-4">
                <h3 className="font-medium">{t('Task')}</h3>
                <div className="flex flex-col gap-4">
                  <FormInput
                    label={t('Task Code')}
                    name="reference"
                    id="reference"
                    required
                    placeholder={t('Enter Task code')}
                    minLength={{
                      message:
                        'Task code must be longer than or equal to 8 characters.',
                      value: 8,
                    }}
                  />
                  <hr className="border-grey-200" />
                  <UnitsInput name="units" />
                  <hr className="border-grey-200" />
                  <FormDatePicker
                    dateFormat="dd.MM.yyyy"
                    isClearable
                    label={t('Execution date')}
                    minDate={new Date()}
                    name="date"
                    id="date"
                    placeholderText={t('Select date')}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <h3 className="font-medium">{t('Customer')}</h3>
                <div className="flex flex-col gap-4">
                  <FormInput
                    label={t('First name')}
                    name="firstName"
                    id="firstName"
                    required
                    placeholder={t("Enter customer's first name")}
                  />
                  <FormInput
                    label={t('Last name')}
                    name="lastName"
                    id="lastName"
                    required
                    placeholder={t("Enter customer's last name")}
                  />
                  <hr className="border-grey-200" />
                  <FormInput
                    label={t('Address')}
                    name="street"
                    id="street"
                    required
                    placeholder={t('Enter address and street number')}
                  />
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <FormInput
                        label={t('Postcode')}
                        name="postcode"
                        id="postcode"
                        required
                        placeholder={t('Enter postcode')}
                      />
                    </div>
                    <div className="flex-1">
                      <FormInput
                        label={t('City')}
                        name="city"
                        id="city"
                        required
                        placeholder={t('Enter city name')}
                      />
                    </div>
                  </div>
                  <FormInput
                    label={t('Address Details')}
                    placeholder={t('Floor, apartment, other')}
                    name="addressNote"
                    id="addressNote"
                  />
                  <div>
                    <FormInput
                      label={t('Address Instructions')}
                      placeholder={t(
                        'e.g. Doorbell not functional, call on the cell',
                      )}
                      name="endCustomerNote"
                      id="endCustomerNote"
                    />
                  </div>
                </div>
                <hr className="border-grey-200" />
                <FormPhoneInput
                  name="phoneNumber"
                  id="phoneNumber"
                  label={t('Phone number')}
                />
                <FormInput
                  id="email"
                  label={t('E-mail')}
                  name="email"
                  placeholder={t('Enter e-mail address')}
                  type="email"
                />
              </div>
            </div>
          }
          footer={
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <Button
                className="order-last sm:order-none"
                data-test="modal-button-cancel"
                text={t('Cancel')}
                variant="outlineBlack"
                onClick={modalOnClose}
              />
              <Tooltip
                hidden={!mutation.isLoading && isDirty && isValid}
                text={t(
                  'Ensure all mandatory fields are completed before proceeding',
                )}
              >
                <Button
                  className="w-full"
                  data-test="modal-button-action"
                  disabled={mutation.isLoading || !isDirty || !isValid}
                  text={t('Save')}
                  variant="solidBlue"
                  onClick={mutation.mutate}
                />
              </Tooltip>
            </div>
          }
        />
      </FormProvider>
      <BlockNavigation shouldBlock={isDirty}>
        {(blocker) => (
          <CancelPrompt
            title={t('Cancel Manual Data Import?')}
            isOpen={blocker.state === 'blocked'}
            onClose={() => {
              if (blocker.state === 'blocked') {
                blocker.reset();
              }
            }}
            onExitClick={() => {
              if (blocker.state === 'blocked') {
                blocker.proceed();
              }
              onClose();
            }}
          />
        )}
      </BlockNavigation>
      <CancelPrompt
        title={t('Cancel Manual Data Import?')}
        isOpen={isPromptVisible}
        onClose={() => {
          setIsPromptVisible(false);
        }}
        onExitClick={() => {
          setIsPromptVisible(false);
          onClose();
        }}
      />
    </>
  );
};

ManualDataImport.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

ManualDataImport.defaultProps = {
  isOpen: false,
  onClose: undefined,
};

export default ManualDataImport;
