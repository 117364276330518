import useConfirmTabClose from '../../hooks/useConfirmTabClose';
import useFetch from '../../lib/api/hooks/useFetch';

const BrowserClosePrompt = () => {
  const { fetchRequestsActive } = useFetch();

  const showPrompt = fetchRequestsActive > 0;
  useConfirmTabClose(showPrompt);
  return null;
};

export default BrowserClosePrompt;
