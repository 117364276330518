import PropTypes from 'prop-types';

import Icon from '../../components/Icon';

const CloseButton = ({ closeToast }) => (
  <Icon className="mr-3 h-4 w-4 opacity-40" icon="close" onClick={closeToast} />
);
CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

CloseButton.defaultProps = {
  closeToast: () => {},
};

export default CloseButton;
