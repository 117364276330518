import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BadgeIndicator from '../../../components/BadgeIndicator';
import Dropdown from '../../../components/Dropdown/Dropdown';
import IconButton from '../../../components/IconButton';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import useIsStuck from '../../../hooks/useIsStuck';
import useTasksPageSearchParams from '../../../hooks/useTasksPageSearchParams';
import useResourceQuery from '../../../lib/api/hooks/useResourceQuery';
import Resource from '../../../lib/api/Resource';
import FloatingContainerProvider from '../../../providers/FloatingContainerProvider';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import { useTitlebarHeight } from '../../../providers/TitlebarHeightProvider';
import MobileListVisibleContent from '../../shared/MobileListVisibleContent';
import RoutingCycleInfo from '../../shared/RoutingCycleInfo';
import TasksViewToggle from '../../shared/TasksViewToggle';
import TasksBulkEdit from '../TaskBulkEdit';
import TasksCardList from '../TasksCardList';
import TasksFiltersDrawer from '../TasksFiltersDrawer';
import TasksSortDropdown from '../TasksSortDropdown';

const itemsPerPage = 15;

const TasksPageMobile = () => {
  const { t } = useTranslation();

  const { allParams, hubIdQuery, isFilterApplied } = useTasksPageSearchParams();
  const { totalTitlebarHeight } = useTitlebarHeight();
  const stickyElementRef = useRef(null);
  const isStuck = useIsStuck(stickyElementRef);
  const { enableSelection, isSelectionVisible, setIsSelectionDisabled } =
    useListSelection();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const { queryKey } = useResourceQuery({
    itemsPerPage,
    resourceUrl: '/tasks',
    params: allParams,
  });

  const { data } = useQuery(queryKey);
  const isBulkFiltersApplied = Boolean(hubIdQuery);

  const visiblePageContentRef = useRef(null);

  useEffect(() => {
    if (isBulkFiltersApplied) {
      setIsSelectionDisabled(false);
    } else {
      setIsSelectionDisabled(true);
    }
  }, [isBulkFiltersApplied, setIsSelectionDisabled]);

  return (
    <Page>
      <TasksFiltersDrawer
        onClose={() => setIsFilterDrawerOpen(false)}
        isOpen={isFilterDrawerOpen}
      />
      <Titlebar
        textPrimary={t('Tasks view')}
        titleRightContent={<TasksViewToggle />}
        showTitleRightContentInCollapse={false}
      />
      {!isSelectionVisible && (
        <div className="bg-white p-4 pb-2">
          <RoutingCycleInfo />
        </div>
      )}
      <div
        ref={stickyElementRef}
        className={cn(
          'bg-white px-4 pb-2 sticky z-10 border-b transition-[padding]',
          isStuck ? 'border-grey-200 pt-3' : 'border-transparent pt-2',
        )}
        style={{
          top: `calc(var(--topbar-height) + ${totalTitlebarHeight}px)`,
        }}
      >
        {isSelectionVisible && (
          <div className="mb-5 mt-4">
            <TasksBulkEdit isBulkFiltersApplied={isBulkFiltersApplied} />
          </div>
        )}
        <div className="flex gap-2 justify-between">
          <div className="flex flex-col flex-grow-0 overflow-hidden">
            <TasksSortDropdown />
            <div className="text-sm pt-3">
              <span className="text-grey-700">{t('Task count')}:</span>{' '}
              <span className="font-medium">{data?.count || 0}</span>
            </div>
          </div>
          <div className="flex gap-2">
            <BadgeIndicator variant="warning" isVisible={isFilterApplied}>
              <IconButton
                onClick={() => setIsFilterDrawerOpen(true)}
                iconSize="s"
                icon="filter"
                variant="outlineBlack"
              />
            </BadgeIndicator>
            <Dropdown
              className="h-min"
              menu={[
                {
                  icon: 'edit',
                  iconClassName: 'text-grey-700',
                  onClick: () => {
                    enableSelection();
                  },
                  text: t('Bulk edit mode'),
                },
              ]}
              isDisabled={isSelectionVisible}
            >
              <IconButton
                iconSize="s"
                icon="menu"
                variant="outlineBlack"
                disabled={isSelectionVisible}
              />
            </Dropdown>
          </div>
        </div>
      </div>
      <Page.Content variant="grey">
        <FloatingContainerProvider
          floatingContainer={visiblePageContentRef?.current}
        >
          <Resource
            itemsPerPage={itemsPerPage}
            showPageSizePicker={false}
            params={allParams}
            resourceUrl="/tasks"
            socketEntityName="task"
          >
            <TasksCardList />
          </Resource>
          <MobileListVisibleContent
            ref={visiblePageContentRef}
            stickyElementRef={stickyElementRef}
          />
        </FloatingContainerProvider>
      </Page.Content>
    </Page>
  );
};

export default TasksPageMobile;
