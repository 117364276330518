import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Icon from '../Icon';

const buttonSizesMap = {
  m: 'h-10 min-h-[40px] px-4 text-sm gap-2',
  s: 'h-8 min-h-[32px] px-3 text-sm gap-1.5',
};

const iconSizesMap = {
  m: 'w-4 h-4',
  s: 'w-3 h-3',
};

const variantsMap = {
  ghostBlack: {
    className:
      'border-transparent bg-transparent text-primary-dark hover:bg-grey-200 active:bg-grey-200',
  },
  ghostBlue: {
    className:
      'border-transparent bg-transparent text-ui-blue hover:bg-grey-200 active:bg-grey-200',
  },
  ghostRed: {
    className:
      'border-transparent bg-transparent text-ui-red hover:bg-grey-200 active:bg-grey-200',
  },
  outlineBlack: {
    className:
      'border-grey-500 bg-white text-primary-dark hover:bg-grey-200 active:bg-grey-200',
  },
  outlineBlue: {
    className:
      'border-grey-500 bg-white text-ui-blue hover:bg-grey-200 active:bg-grey-200',
  },
  outlineRed: {
    className:
      'border-grey-500 bg-white text-ui-red hover:bg-grey-200 active:bg-grey-200',
  },
  solidBlack: {
    className:
      'border-transparent bg-primary-dark text-white hover:bg-[rgba(29,29,27,0.8)] active:bg-[rgba(29,29,27,0.8)]',
  },
  solidBlue: {
    className:
      'border-transparent bg-ui-blue text-white hover:bg-[rgba(56,103,214,0.8)] active:bg-[rgba(56,103,214,0.8)]',
  },
  solidRed: {
    className:
      'border-transparent bg-ui-red text-white hover:bg-[rgba(245,59,87,0.8)] active:bg-[rgba(245,59,87,0.8)]',
  },
};

const Button = forwardRef((props, ref) => {
  const {
    as: Component,
    className,
    'data-test': dataTest,
    disabled,
    icon,
    iconClassName,
    iconRight,
    iconSize,
    isFullWidth,
    isUppercase,
    onClick,
    postHogName,
    size,
    text,
    variant,
    ...rest
  } = props;

  return (
    <Component
      className={cn(
        'inline-flex flex-shrink-0 cursor-pointer appearance-none items-center justify-center whitespace-nowrap rounded-md border font-medium transition-colors focus:border-primary-yellow active:border-primary-yellow disabled:pointer-events-none disabled:opacity-40',
        isFullWidth && 'w-full',
        isUppercase && 'uppercase',
        buttonSizesMap[size],
        variantsMap[variant]?.className,
        className,
      )}
      data-test={dataTest}
      disabled={disabled}
      ref={ref}
      type="button"
      onClick={(e) => {
        if (disabled) {
          return;
        }

        onClick(e);
      }}
      {...rest}
    >
      {icon && (
        <Icon
          className={cn(iconSizesMap[iconSize], iconClassName)}
          icon={icon}
        />
      )}
      <span className="truncate">{text}</span>
      {iconRight && (
        <Icon
          className={cn(iconSizesMap[iconSize], iconClassName)}
          icon={iconRight}
        />
      )}
    </Component>
  );
});

Button.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  'data-test': PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconRight: PropTypes.string,
  iconSize: PropTypes.oneOf(Object.keys(iconSizesMap)),
  isFullWidth: PropTypes.bool,
  isUppercase: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(buttonSizesMap)),
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
  onClick: PropTypes.func,
  iconClassName: PropTypes.string,
  postHogName: PropTypes.string,
};

Button.defaultProps = {
  as: 'button',
  className: '',
  'data-test': undefined,
  disabled: false,
  icon: '',
  iconRight: '',
  iconClassName: undefined,
  iconSize: 'm',
  isFullWidth: false,
  isUppercase: false,
  size: 'm',
  variant: 'solidBlack',
  onClick: () => {},
  postHogName: undefined,
};

export default Button;
