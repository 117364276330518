import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import OrganisationDetailsSettingsCard from '../OrganisationDetailsSettingsCard';

const OrganisationAlternativeOptionsCard = () => {
  const { t } = useTranslation();

  return (
    <OrganisationDetailsSettingsCard
      text={
        <div className="flex items-center gap-2">
          <Icon className="h-4 w-4 text-grey-500" icon="infoFilled" />
          <span>
            {t(
              'Configure this organisation with alternative safe delivery options',
            )}
          </span>
        </div>
      }
      title={t('Alternative safe delivery')}
    >
      <Button
        as={Link}
        className="w-full"
        icon="tune"
        text={t('Options Configuration')}
        to="delivery-options"
        variant="outlineBlue"
      />
    </OrganisationDetailsSettingsCard>
  );
};

export default OrganisationAlternativeOptionsCard;
