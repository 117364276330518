import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';
import CollapsedInfo from './CollapsedInfo';
import DeliveryOptions from './DeliveryOptions';
import ExpandableStatusItem from './ExpandableStatusItem';

const DeliveredStatusItem = (props) => {
  const { isCurrent, item } = props;
  const { watch } = useFormContext();
  const { t } = useTranslation();

  const deliveryOption = watch('deliveryOption');
  const deliveryOptionNote = watch('deliveryOptionNote');
  const { nameTranslations } = useDeliveryOptionsTranslations();
  const text =
    deliveryOption === null
      ? t('Customer (direct)')
      : nameTranslations[deliveryOption];

  return (
    <ExpandableStatusItem
      isCurrent={isCurrent}
      item={item}
      collapsedChildren={<DeliveryOptions />}
    >
      <CollapsedInfo text={text} note={deliveryOptionNote} />
    </ExpandableStatusItem>
  );
};

DeliveredStatusItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  isCurrent: PropTypes.bool,
};

DeliveredStatusItem.defaultProps = {
  item: undefined,
  isCurrent: false,
};

export default DeliveredStatusItem;
