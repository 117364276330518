import * as Sentry from '@sentry/react';
import { useCallback } from 'react';

import UserHelper from '../helpers/UserHelper';
import getCurrentLanguage from '../translations/getCurrentLanguage';

const useSentry = () => {
  const setSentryUser = useCallback((user) => {
    const username = UserHelper.getInitials(user);
    Sentry.setUser({ role: user.role, username });

    Sentry.withScope((scope) => {
      const scopeUser = scope.getUser();
      // eslint-disable-next-line no-console
      console.log('set sentry user:', scopeUser);
    });
  }, []);

  const unsetSentryUser = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('unset sentry user');
    Sentry.configureScope((scope) => scope.setUser(null));
  }, []);

  const showReportDialog = useCallback((eventId) => {
    Sentry.showReportDialog({ eventId, lang: getCurrentLanguage() });
  }, []);

  return {
    setSentryUser,
    showReportDialog,
    unsetSentryUser,
  };
};

export default useSentry;
