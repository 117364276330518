import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Label from '../../../components/Label';
import OrganisationType from '../../../enums/OrganisationType';

const OrganisationBadge = (props) => {
  const { organisationType } = props;

  const { t } = useTranslation();

  const organisationTypeLabel = {
    [OrganisationType.Carrier]: {
      text: t('Carrier'),
      variant: 'orange',
    },
    [OrganisationType.Superadmin]: {
      text: 'Superadmin',
      variant: 'greyOutline',
    },
  };

  return (
    <Label
      size="s"
      text={organisationTypeLabel[organisationType]?.text}
      variant={organisationTypeLabel[organisationType]?.variant}
    />
  );
};

OrganisationBadge.propTypes = {
  organisationType: PropTypes.oneOf(Object.values(OrganisationType)),
};

OrganisationBadge.defaultProps = {
  organisationType: OrganisationType.Carrier,
};

export default OrganisationBadge;
