import { useCallback, useEffect, useId } from 'react';
import {
  useBeforeUnload,
  useBlocker as useBlockerOriginal,
} from 'react-router-dom';

const useBlocker = (shouldBlock, shouldBlockUnload = false) => {
  const id = useId();
  let shouldBlockBeforeUnload;
  if (typeof shouldBlock === 'boolean') {
    shouldBlockBeforeUnload = shouldBlock;
  } else {
    shouldBlockBeforeUnload = shouldBlockUnload;
  }

  useEffect(() => {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.log('[useBlocker] registered - blocker id:', id);
    }

    return () => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log('[useBlocker] unregistered - blocker id:', id);
      }
    };
  }, [id]);

  const beforeUnload = useCallback(
    (event) => {
      if (shouldBlockBeforeUnload) {
        event.preventDefault();
        // needed for beforeUnload to work https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    },
    [shouldBlockBeforeUnload],
  );
  useBeforeUnload(beforeUnload, { capture: true });

  return useBlockerOriginal(shouldBlock);
};

export default useBlocker;
