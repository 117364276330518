import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { fetch as whatWgFetch } from 'whatwg-fetch';

import ApiErrorCode from '../../../enums/ApiErrorCode';
import useApiErrorTranslation from '../../../hooks/useApiErrorTranslation';
import useCustomToast from '../../../hooks/useCustomToast';
import useLogout from '../../../hooks/useLogout';
import { useSocketId } from '../../../providers/SocketIdProvider';
import { useUser } from '../../../providers/UserProvider';

const FetchContext = createContext({
  fetch: () => {},
  fetchRequestsActive: 0,
  refreshToken: () => {},
});

const sleep = (ms) =>
  new Promise((r) => {
    setTimeout(r, ms);
  });

export const FetchProvider = (props) => {
  const { children, fetchFn, onError, url: baseUrl } = props;

  const { socketId } = useSocketId();
  const [fetchRequestsActive, setFetchRequestsActive] = useState(0);
  const { getAuth, setAuth } = useUser();
  const logout = useLogout();
  const { toastError } = useCustomToast();
  const { translateError } = useApiErrorTranslation();
  const { t } = useTranslation();

  const refreshTokenPromise = useRef(null);

  const fetch = useCallback(async (url, options) => {
    try {
      setFetchRequestsActive((count) => count + 1);
      const response = await whatWgFetch(url, options);
      setFetchRequestsActive((count) => count - 1);
      return response;
    } catch (e) {
      setFetchRequestsActive((count) => count - 1);
      throw e;
    }
  }, []);

  const handleError = useCallback(async (response) => {
    let errorJSON;
    let errorText;
    if (response.text) {
      errorText = await response.clone().text();
      try {
        errorJSON = JSON.parse(errorText);
        return { ...errorJSON, status: response.status };
      } catch (jsonParseError) {
        return jsonParseError;
      }
    }
    return response;
  }, []);

  const checkIfRefreshTokenChanged = useCallback(
    async (currentRefreshTokenValue) => {
      // wait for 50ms before checking
      await sleep(50);
      const newTokenValue = getAuth()?.refreshToken;
      if (currentRefreshTokenValue === newTokenValue) {
        // if tokens are the same, check again
        return checkIfRefreshTokenChanged(currentRefreshTokenValue);
      }

      return true;
    },
    [getAuth],
  );

  const refreshTokenFn = useCallback(async () => {
    const originalRefreshToken = getAuth()?.refreshToken;
    if (!getAuth()) {
      return undefined;
    }

    const response = await fetch(`${baseUrl}/users/refresh-token`, {
      body: JSON.stringify({
        refreshToken: getAuth()?.refreshToken,
      }),
      headers: {
        Authorization: `Bearer ${getAuth()?.accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const result = await response.clone().json();

    if (response.ok) {
      const {
        data: { accessToken, refreshToken },
      } = result;

      const auth = getAuth();

      // if no auth, the app is already logged out. don't set auth again
      if (auth === undefined) {
        logout();
        return undefined;
      }

      setAuth({
        accessToken,
        refreshToken,
      });
    } else if (
      result?.data?.errorCode === ApiErrorCode.TokenRefreshAlreadyUsed
    ) {
      // Race between 50ms recursive check and 5000ms absolute timeout. If the token has not changed after 5000ms, continue execution.
      // This means that the tab that made refresh might have been closed and we need to prevent endless recursion loop.
      await Promise.any([
        sleep(5000),
        checkIfRefreshTokenChanged(originalRefreshToken),
      ]);
      const newRefreshToken = getAuth()?.refreshToken;
      const isRefreshTokenNew = newRefreshToken !== originalRefreshToken;

      // If refresh token from local storage is newer than originally used for refresh request, then the refresh successfully occurred in other tab
      // return "fake" successful response so the new fetch could occur, instead of logging out the user
      if (isRefreshTokenNew) {
        return {
          ok: true,
        };
      }
      logout();
      toastError(`${translateError(result)} ${t('User logged out.')}`);
    } else {
      logout();
      toastError(`${translateError(result)} ${t('User logged out.')}`);
    }
    return response;
  }, [
    getAuth,
    fetch,
    baseUrl,
    setAuth,
    logout,
    checkIfRefreshTokenChanged,
    toastError,
    translateError,
    t,
  ]);

  const refreshToken = useCallback(() => {
    if (refreshTokenPromise.current) {
      return refreshTokenPromise.current;
    }
    refreshTokenPromise.current = refreshTokenFn();
    // always clear promise ref on completion
    refreshTokenPromise.current.finally(() => {
      refreshTokenPromise.current = null;
    });
    return refreshTokenPromise.current;
  }, [refreshTokenFn]);

  const doFetch = useCallback(
    async (url, options = {}) => {
      const finalUrl = `${baseUrl}${url}`;

      const headers = options.headers || {
        'Content-Type': 'application/json',
      };

      const accessToken = getAuth()?.accessToken;

      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }

      if (socketId) {
        // all lowercase
        headers.socketid = socketId;
      }

      const optionsClone = { ...options, headers };
      if (
        options.body &&
        typeof options.body === 'object' &&
        !(options.body instanceof FormData)
      ) {
        optionsClone.body = JSON.stringify(options.body);
      }

      const originalResponse = await fetch(finalUrl, optionsClone);

      if (originalResponse.ok) {
        return originalResponse;
      }

      const originalError = await handleError(originalResponse);

      if (originalError) {
        const { data: error } = originalError;
        if (error?.errorCode === ApiErrorCode.Unauthorized) {
          return refreshToken().then(async (refreshTokenResponse) => {
            if (refreshTokenResponse?.ok) {
              headers.Authorization = `Bearer ${getAuth()?.accessToken}`;
              const newResponse = await fetch(finalUrl, {
                ...options,
                headers,
              });
              if (newResponse.ok) {
                return newResponse;
              }

              const newError = await handleError(newResponse);
              onError(newError);
              throw newError;
            }

            onError(originalError);
            throw await handleError(refreshTokenResponse);
          });
        }
      }

      onError(originalError);
      throw originalError;
    },
    [baseUrl, fetch, getAuth, handleError, onError, refreshToken, socketId],
  );

  const value = useMemo(
    () => ({ fetch: fetchFn || doFetch, fetchRequestsActive, refreshToken }),
    [doFetch, fetchFn, fetchRequestsActive, refreshToken],
  );

  return (
    <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
  );
};

FetchProvider.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  fetchFn: PropTypes.func,
  onError: PropTypes.func,
};

FetchProvider.defaultProps = {
  children: null,
  url: '',
  fetchFn: undefined,
  onError: () => {},
};

export default () => useContext(FetchContext);
