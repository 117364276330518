// fix for import https://github.com/moment/moment/issues/5926#issuecomment-1056785251
import 'moment/dist/locale/de';
import './translations';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import moment from 'moment';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from './app/App';
import getCurrentLanguage from './translations/getCurrentLanguage';

let version = import.meta.env.VITE_VERSION;
// ignore locally
if (import.meta.env.VITE_BUILD_SHA) {
  version = version.concat(`-${import.meta.env.VITE_BUILD_SHA}`);
}
// eslint-disable-next-line no-console
console.log(version);

moment.locale(getCurrentLanguage());

Sentry.init({
  autoSessionTracking: true,
  dsn: 'https://5dd8184be3d240688f7227cb524c97bb@o1009310.ingest.sentry.io/5973431',
  // Prevent Sentry for monitoring in dev mode, but still show prompts and enable working with Sentry client APIs (e.g. Sentry.showReportDialog).
  enabled: import.meta.env.PROD,
  environment: import.meta.env.MODE,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  release: `urbify-dashboard@${version}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.2,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
