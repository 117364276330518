import PropTypes from 'prop-types';

import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import FailureReason from '../../../enums/FailureReason';
import ReturnReason from '../../../enums/ReturnReason';
import UnitEventType from '../../../enums/UnitEventType';

const eventType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.oneOf(Object.values(UnitEventType)),
  timestamp: PropTypes.string,
  status: PropTypes.oneOf(Object.values(DeliveryTaskUnitStatus)),
  statusNote: PropTypes.string,
  note: PropTypes.string,
  shiftNumber: PropTypes.number,
  failureReason: PropTypes.oneOf(Object.values(FailureReason)),
  returnReason: PropTypes.oneOf(Object.values(ReturnReason)),
  attempt: PropTypes.number,
  deliveryOption: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  tourStopUnit: PropTypes.shape({
    id: PropTypes.string,
  }),
  delay: PropTypes.shape({
    description: PropTypes.string,
    reason: PropTypes.string,
    type: PropTypes.string,
    durationInMinutes: PropTypes.number,
  }),
  hub: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  tourStop: PropTypes.shape({
    id: PropTypes.string,
    windowFrom: PropTypes.string,
    windowTo: PropTypes.string,
  }),
  endCustomerNoticeSent: PropTypes.bool,
  newAddressDetails: PropTypes.string,
  oldAddressDetails: PropTypes.string,
  reference: PropTypes.string,
  previousReference: PropTypes.string,
});

export default eventType;
