import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import Breakpoint from '../../enums/Breakpoint';
import useBorderBoxSize from '../../hooks/useBorderBoxSize';
import useWindowSize from '../../hooks/useWindowSize';
import Icon from '../Icon';
import DatePickerVariant from './DatePickerVariant';

const variantsMap = {
  [DatePickerVariant.Default]: {
    iconClassName: 'w-5 h-5',
    clearIconClassName:
      'peer-focus:text-primary-yellow text-grey-700 w-4 h-4 right-[11px]',
    input:
      'border border-grey-500 focus:border-primary-yellow rounded-md h-10 pl-3 pr-2.5 w-full text-sm',
  },
  [DatePickerVariant.Filter]: {
    wrapper: 'w-fit',
    iconClassName: 'w-4 h-4',
    clearIconClassName: 'text-ui-blue right-2.5 p-[3px]',
    input: 'border rounded-lg h-9 text-xs pl-3 pr-[30px]',
    inputInactive: 'border-grey-300',
    inputActive:
      'bg-ui-blue-light border-transparent focus:border-ui-blue text-ui-blue font-semibold',
  },
};

const DatePickerInput = forwardRef(
  (
    {
      active,
      autoComplete,
      className,
      disabled,
      error,
      isClearable,
      minWidth,
      onChange,
      onClick,
      placeholder,
      value,
      variant,
      ...props
    },
    ref,
  ) => {
    const hiddenSpanRef = useRef(null);
    const [width, setWidth] = useState(minWidth);
    const { width: windowWidth } = useWindowSize();
    const isMobile = windowWidth < Breakpoint.SM;

    const [setSpanBlockRef, spanBorderBoxSize] = useBorderBoxSize();

    useEffect(() => {
      if (variant !== DatePickerVariant.Filter) {
        return;
      }

      const inlineSize = Math.round(spanBorderBoxSize?.inlineSize);
      if (
        inlineSize >= minWidth &&
        inlineSize === hiddenSpanRef?.current?.offsetWidth &&
        width !== inlineSize
      ) {
        // this calculation is required for initial loading, when font load changes the inlineSize.
        // as width is updated only if value changes, we need to manually update width if this case
        setWidth(Math.max(inlineSize || 0, minWidth));
      }
    }, [minWidth, spanBorderBoxSize?.inlineSize, variant, width]);

    useLayoutEffect(() => {
      if (variant !== DatePickerVariant.Filter) {
        return;
      }

      if (!value) {
        setWidth(minWidth);
      } else {
        setWidth(hiddenSpanRef?.current?.offsetWidth);
      }
    }, [minWidth, value, variant]);

    const isClearableIconVisible = value && isClearable;

    const onClearClick = () => {
      onChange({
        target: {
          value: '',
        },
      });

      if (ref?.current?.focus) {
        ref.current.focus();
      }
    };

    return (
      <div
        className={cn(
          'relative',
          variantsMap[variant].wrapper,
          disabled && 'opacity-40',
        )}
      >
        {!value && (
          <div className="pointer-events-none absolute right-3 text-grey-700 top-1/2 -translate-y-1/2">
            <Icon className={variantsMap[variant].iconClassName} icon="date" />
          </div>
        )}
        {/* Hidden element for correct width calculation */}
        <span
          ref={(el) => {
            hiddenSpanRef.current = el;
            setSpanBlockRef(el);
          }}
          className={cn(
            '!h-0 !border-transparent overflow-hidden whitespace-pre absolute invisible',
            variantsMap[variant]?.input,
            active
              ? variantsMap[variant]?.inputActive
              : variantsMap[variant]?.inputInactive,
          )}
        >
          {value}
        </span>
        <input
          disabled={disabled}
          style={{
            width:
              variant === DatePickerVariant.Filter ? `${width}px` : undefined,
          }}
          autoComplete="off"
          maxLength={11}
          className={cn(
            'cursor-pointer outline-none text-primary-dark placeholder:text-grey-700 w-full peer disabled:bg-grey-200 disabled:cursor-not-allowed',
            error && 'border-ui-red',
            variantsMap[variant]?.input,
            active
              ? variantsMap[variant]?.inputActive
              : variantsMap[variant]?.inputInactive,
          )}
          data-test="date-picker-input"
          ref={ref}
          type="text"
          value={value}
          onChange={onChange}
          onClick={onClick}
          placeholder={placeholder}
          {...props}
          readOnly={isMobile}
        />
        {isClearableIconVisible && (
          <div
            className={cn(
              'absolute top-1/2 -translate-y-1/2',
              variantsMap[variant]?.clearIconClassName,
              disabled && 'pointer-events-none',
            )}
            onClick={(e) => {
              if (disabled) {
                return;
              }
              onClearClick(e);
            }}
            tabIndex={-1}
            role="button"
          >
            {variant === DatePickerVariant.Filter ? (
              <Icon className="w-2.5 h-2.5" icon="close" />
            ) : (
              <Icon icon="xCircleFilled" />
            )}
          </div>
        )}
      </div>
    );
  },
);

DatePickerInput.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(DatePickerVariant)),
  active: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  minWidth: PropTypes.number,
};

DatePickerInput.defaultProps = {
  autoComplete: undefined,
  className: undefined,
  error: undefined,
  isClearable: undefined,
  onChange: undefined,
  onClick: undefined,
  value: undefined,
  variant: DatePickerVariant.Default,
  active: false,
  placeholder: undefined,
  disabled: false,
  minWidth: 75,
};

export default DatePickerInput;
