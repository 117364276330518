import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Icon from '../Icon';

const Checkbox = forwardRef((props, ref) => {
  const {
    checked,
    className,
    'data-test': dataTest,
    disabled,
    label,
    name,
    onChange,
    onClick,
    readOnly,
    value,
  } = props;

  return (
    <label
      className={cn(
        'relative flex items-center gap-2 text-xs',
        !disabled && !readOnly && 'cursor-pointer',
        (disabled || readOnly) && 'cursor-not-allowed',
        label && 'py-2.5',
        className,
      )}
      htmlFor={value}
    >
      <input
        checked={checked}
        className={cn(
          'peer h-4 w-4 appearance-none rounded border border-grey-500 bg-white checked:border-transparent checked:bg-ui-blue disabled:pointer-events-none disabled:bg-grey-300 disabled:checked:bg-ui-blue disabled:opacity-40 cursor-pointer',
          readOnly && 'opacity-40',
        )}
        data-test={dataTest}
        disabled={disabled}
        id={value}
        name={name}
        ref={ref}
        type="checkbox"
        value={value}
        onChange={(e) => {
          if (disabled || readOnly) {
            return;
          }
          onChange(e);
        }}
        onClick={(e) => {
          if (disabled || readOnly) {
            return;
          }
          onClick(e);
        }}
      />
      <Icon
        className="pointer-events-none absolute left-0.5 hidden h-3 w-3 text-white peer-checked:block"
        icon="checkmark"
      />
      {label && (
        <span
          className={cn(
            'text-sm text-primary-dark',
            (disabled || readOnly) && 'opacity-40',
          )}
        >
          {label}
        </span>
      )}
    </label>
  );
});

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  'data-test': PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  className: '',
  'data-test': undefined,
  disabled: false,
  readOnly: false,
  label: undefined,
  name: undefined,
  onChange: () => {},
  onClick: () => {},
  value: undefined,
};

export default Checkbox;
