import moment from 'moment';
import PropTypes from 'prop-types';
import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DatePicker from '../../../components/DatePicker';
import DatePickerVariant from '../../../components/DatePicker/DatePickerVariant';
import LinkButton from '../../../components/LinkButton';
import SelectVariant from '../../../components/Select/SelectVariant';
import useTasksPageSearchParams from '../../../hooks/useTasksPageSearchParams';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import ClientSelect from '../../shared/ClientSelect';
import HubSelect from '../../shared/HubSelect';
import ShiftsSelect from '../../shared/ShiftsSelect';
import TasksSortDropdown from '../TasksSortDropdown';
import TasksStatusSelect from '../TasksStatusSelect';
import TasksTypeSelect from '../TasksTypeSelect';

const TasksTableFilters = forwardRef((props, ref) => {
  const { count } = props;

  const {
    clientIdQuery,
    currentSortType,
    dateQuery,
    hubIdQuery,
    searchByQuery,
    searchParams,
    searchQuery,
    setSearchParams,
    shiftQuery,
    statusQuery,
    typeQuery,
  } = useTasksPageSearchParams();
  const { selectedItems } = useListSelection();

  const { i18n, t } = useTranslation();

  const isSelectionActive = selectedItems.length > 0;

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      searchParams.set('page', '1');
      if (!queryValue) {
        searchParams.delete(queryKey);
      } else {
        searchParams.set(queryKey, queryValue);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return (
    <div
      className="flex w-full flex-col flex-wrap justify-between gap-3 lg:flex-row relative px-4 py-2 border-grey-300 rounded-t-md border items-center"
      ref={ref}
    >
      <div className="flex flex-col flex-wrap gap-2 lg:flex-row lg:items-center">
        <div className="flex justify-center items-center">
          <div className="text-xs w-[104px]" title={count}>
            <span className="text-grey-700 align-middle block">
              {t('Task count')}:{' '}
            </span>
            <span className="font-medium text-primary-dark align-middle">
              {count}
            </span>
          </div>
        </div>
        <DatePicker
          variant={DatePickerVariant.Filter}
          dateFormat={["dd.MM.''yy", 'dd.MM.yyyy']}
          isClearable
          name="date"
          placeholderText={t('Execution date')}
          required
          value={dateQuery}
          onChange={(date) => {
            const formattedDate = date && moment(date).format('YYYY-MM-DD');
            onSelectChange('date', formattedDate);
          }}
          minWidth={i18n.language === 'de' ? 165 : 130}
        />

        <ShiftsSelect
          className="lg:max-w-[100px]"
          variant={SelectVariant.Filter}
          value={shiftQuery}
          onChange={(value) => {
            onSelectChange('shiftNumber', value);
          }}
        />

        <TasksStatusSelect
          className="lg:max-w-[220px]"
          variant={SelectVariant.Filter}
          value={statusQuery}
          onChange={(value) => {
            onSelectChange('status', value);
          }}
        />

        <TasksTypeSelect
          className="lg:max-w-[220px]"
          variant={SelectVariant.Filter}
          value={typeQuery}
          onChange={(value) => {
            onSelectChange('type', value);
          }}
        />

        <HubSelect
          disabled={isSelectionActive}
          variant={SelectVariant.Filter}
          className="max-w-[220px]"
          value={hubIdQuery}
          onChange={(hubId) => {
            onSelectChange('hubId', hubId);
          }}
        />

        <ClientSelect
          variant={SelectVariant.Filter}
          className="max-w-[220px]"
          value={clientIdQuery}
          onChange={(clientId) => {
            onSelectChange('clientId', clientId);
          }}
        />

        <LinkButton
          disabled={isSelectionActive}
          className="mx-1"
          text={t('Clear All')}
          onClick={() => {
            const newParams = new URLSearchParams({
              page: 1,
              sortType: currentSortType,
              searchBy: searchByQuery,
              search: searchQuery,
            });

            setSearchParams(newParams);
          }}
        />
      </div>
      <div className="flex flex-col gap-4 lg:flex-row">
        <TasksSortDropdown />
      </div>
    </div>
  );
});

TasksTableFilters.propTypes = {
  count: PropTypes.number,
};

TasksTableFilters.defaultProps = {
  count: 0,
};

export default TasksTableFilters;
