import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '../../../components/Button';
import FormSelect from '../../../components/FormSelect';
import Icon from '../../../components/Icon';
import TimePicker from '../../../components/TimePicker';
import {
  stringToTimePickerObject,
  timePickerObjectToString,
} from './shiftForm.helpers';
import ShiftTypeSection from './ShiftTypeSection';

const ShiftForm = (props) => {
  const {
    deliverySlotEnd,
    deliverySlotStart,
    endTime,
    existingShifts,
    hubId,
    isSubmitting,
    number,
    onCancel,
    onIsDirtyChange,
    onShiftSubmit,
    routingTime,
    shiftType,
    startTime,
  } = props;
  const { t } = useTranslation();
  const { id } = useParams();

  const isEdit = !!id;

  const methods = useForm({
    defaultValues: {
      shiftType,
      hubId,
      number,
      startTime,
      endTime,
      deliverySlotStart,
      deliverySlotEnd,
      routingTime,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
  } = methods;

  const selectedStartTime = watch('startTime');
  const selectedEndTime = watch('endTime');
  const selectedDeliverySlotStart = watch('deliverySlotStart');
  const selectedDeliverySlotEnd = watch('deliverySlotEnd');

  const onSubmit = (data) => {
    if (data.shiftType === 'carrierShift') {
      const { hubId: _, ...submitData } = data;
      onShiftSubmit(submitData);
    } else {
      onShiftSubmit(data);
    }
  };

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, methods, onIsDirtyChange]);

  const numberOptions = [
    {
      value: 1,
      label: 'Shift 1 (Morning)',
    },
    {
      value: 2,
      label: 'Shift 2 (Afternoon)',
    },
    {
      value: 3,
      label: 'Shift 3 (Evening)',
    },
  ];

  useEffect(() => {
    const momentStartTime = moment(selectedStartTime, 'HH:mm');
    const shift3Start = moment('18:00', 'HH:mm');
    const shift2Start = moment('12:00', 'HH:mm');

    if (!momentStartTime.isValid()) {
      return;
    }

    if (momentStartTime.isBefore(shift3Start)) {
      if (momentStartTime.isBefore(shift2Start)) {
        methods.setValue('number', 1);
      } else {
        methods.setValue('number', 2);
      }
    } else {
      methods.setValue('number', 3);
    }
  }, [methods, selectedStartTime]);

  const getStartMaxTime = useCallback((endTimeValue) => {
    const momentEndTime = moment(endTimeValue, 'HH:mm');
    const maxTime = momentEndTime.subtract(1, 'hours');
    return stringToTimePickerObject(maxTime);
  }, []);

  const getEndMinTime = useCallback((startTimeValue) => {
    const momentStartTime = moment(startTimeValue, 'HH:mm');
    const minTime = momentStartTime.add(1, 'hours');
    return stringToTimePickerObject(minTime);
  }, []);

  const validateStartTime = useCallback(
    (_, formValues) => {
      let existingShiftNumbers = [];

      if (formValues.shiftType === 'carrierShift') {
        existingShiftNumbers = existingShifts.map((item) =>
          item.hub ? undefined : item.number,
        );
      }

      if (formValues.shiftType === 'hubShift') {
        existingShiftNumbers = existingShifts.map((item) =>
          item.hub?.id === formValues.hubId ? item.number : undefined,
        );
      }

      if (isEdit) {
        existingShiftNumbers = existingShiftNumbers.filter(
          (item) => item !== number,
        );
      }

      const shiftTypeTranslation =
        formValues.shiftType === 'hubShift' ? t('Hub') : t('Carrier');

      if (existingShiftNumbers.includes(formValues.number)) {
        return t(
          'There is already a {{shiftType}} Shift {{shiftNumber}} registered in the system. Please modify the shift details to avoid duplication.',
          {
            shiftNumber: formValues.number,
            shiftType: shiftTypeTranslation,
          },
        );
      }

      return true;
    },
    [existingShifts, isEdit, number, t],
  );

  const validateDeliverySlotStartTime = useCallback(
    (value) => {
      const momentStartTime = moment(selectedStartTime, 'HH:mm');
      const selectedTime = moment(value, 'HH:mm');

      const diff = selectedTime.diff(momentStartTime, 'minutes');

      if (diff < 30) {
        return t(
          'Ensure the delivery slot aligns with the shift duration: start later, end earlier.',
        );
      }

      return true;
    },
    [selectedStartTime, t],
  );

  const validateDeliverySlotEndTime = useCallback(
    (value) => {
      const momentEndTime = moment(selectedEndTime, 'HH:mm');
      const selectedTime = moment(value, 'HH:mm');

      const diff = momentEndTime.diff(selectedTime, 'minutes');

      if (diff < 30) {
        return t(
          'Ensure the delivery slot aligns with the shift duration: start later, end earlier.',
        );
      }

      return true;
    },
    [selectedEndTime, t],
  );

  const displayDeliverySlotErrors = useCallback(() => {
    if (
      errors?.deliverySlotStart?.type === 'required' ||
      errors?.deliverySlotEnd?.type === 'required'
    ) {
      return t('Delivery slot is required');
    }
    if (errors?.deliverySlotStart?.type === 'validate') {
      return errors?.deliverySlotStart?.message;
    }

    return errors?.deliverySlotEnd?.message;
  }, [
    errors?.deliverySlotEnd?.message,
    errors?.deliverySlotEnd?.type,
    errors?.deliverySlotStart?.message,
    errors?.deliverySlotStart?.type,
    t,
  ]);

  const displayDurationErrors = useCallback(() => {
    if (
      errors?.startTime?.type === 'required' ||
      errors?.endTime?.type === 'required'
    ) {
      return t('Shift duration is required');
    }

    return errors?.startTime?.message;
  }, [
    errors?.endTime?.type,
    errors?.startTime?.message,
    errors?.startTime?.type,
    t,
  ]);

  const validateRouteStartTime = useCallback(
    (value) => {
      const momentStartTime = moment(selectedStartTime, 'HH:mm');
      const selectedTime = moment(value, 'HH:mm');

      const diff = momentStartTime.diff(selectedTime, 'minutes');

      if (diff < 60) {
        return t(
          'Ensure the routing time aligns with the shift: should occur at least one hour earlier.',
        );
      }

      return true;
    },
    [selectedStartTime, t],
  );

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-8"
        onSubmit={(e) => {
          e.stopPropagation();
          return handleSubmit(onSubmit)(e);
        }}
      >
        <div className="flex flex-col gap-5">
          <div>
            <h3 className="grey-200 mb-5 text-base font-semibold">
              {t('Type')}
            </h3>
            <ShiftTypeSection isEdit={isEdit} />
          </div>
          <div className="mt-3">
            <h3 className="grey-200 mb-5 text-base font-semibold">
              {t('Setup')}
            </h3>
            <div className="flex flex-col gap-5 divide-y divide-grey-300 rounded-md border border-grey-300 p-5">
              <div className="flex flex-col gap-3">
                <h3 className="text-sm font-medium">{t('Shift Duration')}*</h3>
                <div className="flex items-center gap-2 text-sm text-grey-700">
                  <Icon
                    className="h-4 w-4 text-ui-info-blue"
                    icon="infoFilled"
                  />
                  <span>
                    {t(
                      "Shift duration refers to the period between a driver's start time and end time for their working shift.",
                    )}
                  </span>
                </div>
                <div className="flex flex-col gap-4 sm:flex-row">
                  <Controller
                    control={control}
                    name="startTime"
                    render={({
                      field: { onChange, ref, value, ...rest },
                      fieldState: { invalid },
                    }) => (
                      <div className="flex flex-col gap-2">
                        <label
                          className="flex gap-1 text-xs font-medium text-primary-dark"
                          htmlFor="startTime"
                        >
                          {t('Start time')}
                        </label>
                        <TimePicker
                          data-test="start-time-picker"
                          value={stringToTimePickerObject(value)}
                          onChange={(timePickerValue) => {
                            onChange(timePickerObjectToString(timePickerValue));
                          }}
                          maxTime={getStartMaxTime(selectedEndTime)}
                          error={invalid}
                          {...rest}
                        />
                      </div>
                    )}
                    rules={{ required: true, validate: validateStartTime }}
                  />
                  <Controller
                    control={control}
                    name="endTime"
                    render={({
                      field: { onChange, ref, value, ...rest },
                      fieldState: { invalid },
                    }) => (
                      <div className="flex flex-col gap-2">
                        <label
                          className="flex gap-1 text-xs font-medium text-primary-dark"
                          htmlFor="endTime"
                        >
                          {t('End time')}
                        </label>
                        <TimePicker
                          data-test="end-time-picker"
                          value={stringToTimePickerObject(value)}
                          onChange={(timePickerValue) =>
                            onChange(timePickerObjectToString(timePickerValue))
                          }
                          minTime={getEndMinTime(selectedStartTime)}
                          error={invalid}
                          {...rest}
                        />
                      </div>
                    )}
                    rules={{ required: true }}
                  />
                </div>
                {(errors.startTime || errors.endTime) && (
                  <div
                    className="text-sm text-ui-red"
                    data-test="duration-slot-error"
                  >
                    {displayDurationErrors()}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-3 pt-5">
                <h3 className="text-sm font-medium">{t('Shift Unit')}*</h3>
                <div data-test="number-select">
                  <FormSelect
                    required
                    id="number"
                    label={t('Unit')}
                    name="number"
                    options={numberOptions}
                    readOnly
                  />
                </div>
              </div>
              <div className="flex flex-col gap-3 pt-5">
                <h3 className="text-sm font-medium">{t('Delivery Slot')}*</h3>
                <div className="flex items-center gap-2 text-sm text-grey-700">
                  <Icon
                    className="h-4 w-4 text-ui-info-blue"
                    icon="infoFilled"
                  />
                  <span>
                    {t(
                      'A delivery slot represents the designated time frame during which deliveries take place. For optimal scheduling, we recommend that the delivery slot starts at least half an hour after the shift begins and ends at least half an hour before the shift ends.',
                    )}
                  </span>
                </div>
                <div className="flex flex-col gap-4 sm:flex-row">
                  <Controller
                    control={control}
                    name="deliverySlotStart"
                    render={({
                      field: { onChange, ref, value, ...rest },
                      fieldState: { invalid },
                    }) => (
                      <div className="flex flex-col gap-2">
                        <label
                          className="flex gap-1 text-xs font-medium text-primary-dark"
                          htmlFor="deliverySlotStart"
                        >
                          {t('Start time')}
                        </label>
                        <TimePicker
                          data-test="delivery-window-start-time-picker"
                          value={stringToTimePickerObject(value)}
                          onChange={(timePickerValue) => {
                            onChange(timePickerObjectToString(timePickerValue));
                          }}
                          maxTime={getStartMaxTime(selectedDeliverySlotEnd)}
                          error={invalid}
                          {...rest}
                        />
                      </div>
                    )}
                    rules={{
                      required: true,
                      validate: validateDeliverySlotStartTime,
                    }}
                  />
                  <Controller
                    control={control}
                    name="deliverySlotEnd"
                    render={({
                      field: { onChange, ref, value, ...rest },
                      fieldState: { invalid },
                    }) => (
                      <div className="flex flex-col gap-2">
                        <label
                          className="flex gap-1 text-xs font-medium text-primary-dark"
                          htmlFor="deliverySlotEnd"
                        >
                          {t('End time')}
                        </label>
                        <TimePicker
                          data-test="delivery-window-end-time-picker"
                          value={stringToTimePickerObject(value)}
                          onChange={(timePickerValue) =>
                            onChange(timePickerObjectToString(timePickerValue))
                          }
                          minTime={getEndMinTime(selectedDeliverySlotStart)}
                          error={invalid}
                          {...rest}
                        />
                      </div>
                    )}
                    rules={{
                      required: true,
                      validate: validateDeliverySlotEndTime,
                    }}
                  />
                </div>
                {(errors.deliverySlotStart || errors.deliverySlotEnd) && (
                  <div
                    data-test="delivery-slot-error"
                    className="text-sm text-ui-red"
                  >
                    {displayDeliverySlotErrors()}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-3 pt-5">
                <h3 className="text-sm font-medium">{t('Routing Time')}*</h3>
                <div className="flex items-center gap-2 text-sm text-grey-700">
                  <Icon
                    className="h-4 w-4 text-ui-info-blue"
                    icon="infoFilled"
                  />
                  <span>
                    {t(
                      'Routing time represents the designated period for planning routes. We recommend scheduling routing time to occur at least an hour prior to the start of the shift.',
                    )}
                  </span>
                </div>
                <div>
                  <div className="flex flex-col gap-4 sm:flex-row">
                    <Controller
                      control={control}
                      name="routingTime"
                      render={({
                        field: { onChange, ref, value, ...rest },
                        fieldState: { invalid },
                      }) => (
                        <div className="flex flex-col gap-2">
                          <label
                            className="flex gap-1 text-xs font-medium text-primary-dark"
                            htmlFor="routingTime"
                          >
                            {t('Start time')}
                          </label>
                          <TimePicker
                            data-test="routing-time-picker"
                            value={stringToTimePickerObject(value)}
                            onChange={(timePickerValue) =>
                              onChange(
                                timePickerObjectToString(timePickerValue),
                              )
                            }
                            error={invalid}
                            {...rest}
                          />
                        </div>
                      )}
                      rules={{
                        required: t('Routing time is required'),
                        validate: validateRouteStartTime,
                      }}
                    />
                  </div>
                </div>
                {errors.routingTime && (
                  <div
                    data-test="routing-time-error"
                    className="text-sm text-ui-red"
                  >
                    {errors.routingTime.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom h-0.5 w-full border border-grey-200" />
          <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
            <Button
              className="order-last sm:order-none sm:flex-1"
              disabled={isSubmitting}
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={onCancel}
            />
            <Button
              className="sm:flex-1"
              data-test="shift-form-submit"
              disabled={isSubmitting}
              text={isEdit ? t('Save Changes') : t('Add Shift')}
              type="submit"
              variant="solidBlue"
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

ShiftForm.propTypes = {
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  shiftType: PropTypes.oneOf(['carrierShift', 'hubShift']),
  number: PropTypes.number,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  routingTime: PropTypes.string,
  deliverySlotStart: PropTypes.string,
  deliverySlotEnd: PropTypes.string,
  hubId: PropTypes.string,
  onIsDirtyChange: PropTypes.func,
  onShiftSubmit: PropTypes.func,
  existingShifts: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      hub: PropTypes.shape({
        hubId: PropTypes.string,
      }),
    }),
  ),
};

ShiftForm.defaultProps = {
  shiftType: 'carrierShift',
  hubId: undefined,
  isSubmitting: false,
  number: undefined,
  startTime: '',
  endTime: '',
  deliverySlotStart: '',
  deliverySlotEnd: '',
  routingTime: '',
  onIsDirtyChange: () => null,
  onCancel: () => null,
  onShiftSubmit: () => null,
  existingShifts: [],
};

export default ShiftForm;
