const LocalStorage = {
  Auth: 'auth',
  IsSidebarCollapsed: 'isSidebarCollapsed',
  Language: 'language',
  ScanFeedAutoPrint: 'scanFeedAutoPrint',
  ScanLabelSize: 'scanLabelSize',
  ScanSessionEnd: 'scanSessionEnd',
  ScanningHubType: 'scanningHubType',
  User: 'user',
  TasksPageViewPreference: 'tasksPageViewPreference',
  UnitsColumnsVisibilitySettings: 'unitsColumnsVisibilitySettings',
  TasksColumnsVisibilitySettings: 'tasksColumnsVisibilitySettings',
};

export default LocalStorage;
