import { useTranslation } from 'react-i18next';

import LinkButton from '../../../../components/LinkButton';
import UserPopover from '../../../shared/UserPopover';
import eventType from '../eventType';

const UserField = (props) => {
  const { event } = props;
  const { t } = useTranslation();

  return event.user ? (
    <div className="relative">
      <UserPopover placement="bottom-start" user={event.user}>
        <LinkButton
          size="s"
          text={`${event.user.firstName} ${event.user.lastName}`}
        />
      </UserPopover>
    </div>
  ) : (
    <div className="text-grey-700 text-sm">{t('System automatic')}</div>
  );
};

UserField.propTypes = {
  event: eventType.isRequired,
};

export default UserField;
