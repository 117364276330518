/* eslint-disable react/no-unstable-nested-components */
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import LinkButton from '../../../components/LinkButton';
import NewTable, {
  CellAlignment,
  CellPadding,
} from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import LocalStorage from '../../../enums/LocalStorage';
import LocalStorageHelper from '../../../helpers/LocalStorageHelper';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import { useUser } from '../../../providers/UserProvider';
import EndCustomerPopover from '../../shared/EndCustomerPopover';
import ShiftLabel from '../../shared/ShiftLabel';
import UnitDetailsDrawer from '../../shared/UnitDetailsDrawer';
import UnitsStatusAndChangeTime from '../../shared/UnitsStatusAndChangeTime';
import UnitStatusUpdate from '../../shared/UnitStatusUpdate';
import { tasksTableId } from '../../tasks/TasksTable';
import CancelUnitModal from '../CancelUnitModal';
import UpdateUnitCode from '../UpdateUnitCode';
import UnitsTableActionsCell from './UnitsTableActionsCell';
import UnitsTableFilters from './UnitsTableFilters';
import UnitsTableHeaderActions from './UnitsTableHeaderActions';

const ShiftLabelCell = ({ getValue }) => (
  <ShiftLabel shiftNumber={getValue()} />
);
ShiftLabelCell.propTypes = {
  getValue: PropTypes.func.isRequired,
};

const TourCell = ({ row: { original: record } }) => {
  const tour = record?.latestTour;

  const text = useMemo(() => {
    if (tour?.totalStops && tour?.tourStop?.sequence) {
      return `${tour.vehicle} ${tour.tourStop.sequence}/${tour.totalStops}`;
    }
    return tour?.vehicle;
  }, [tour?.totalStops, tour?.tourStop?.sequence, tour?.vehicle]);

  if (!tour) {
    return <span>N/A</span>;
  }

  return (
    <LinkButton
      as={Link}
      text={text}
      state={{
        backLink: -1,
      }}
      to={{
        pathname: `/tours/${tour.id}`,
        search: `?stopId=${tour.tourStop?.id}`,
      }}
    />
  );
};

TourCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      latestTour: PropTypes.shape({
        id: PropTypes.string,
        vehicle: PropTypes.string,
        totalStops: PropTypes.number,
        tourStop: PropTypes.shape({
          id: PropTypes.string,
          sequence: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
};

const UnitsTable = (props) => {
  const { data, total, ...rest } = props;
  const { t } = useTranslation();
  const { user } = useUser();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [unitForCodeUpdate, setUnitForCodeUpdate] = useState(null);
  const [unitForStatusUpdate, setUnitForStatusUpdate] = useState(null);
  const { isSelectionVisible } = useListSelection();
  const [unitForDetailsView, setUnitForDetailsView] = useState(null);
  const [unitForCodeCancel, setUnitForCodeCancel] = useState(null);
  const { i18n } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    if (location) {
      // Close drawers when location changes
      setIsDrawerOpen(false);
      setUnitForCodeUpdate(null);
      setUnitForStatusUpdate(null);
      setUnitForDetailsView(null);
    }
  }, [location]);

  const columnVisibilityState = JSON.parse(
    LocalStorageHelper.getItem(LocalStorage.UnitsColumnsVisibilitySettings),
  );

  const onColumnVisibilityChange = (newColumnVisibilityState) => {
    LocalStorageHelper.setItem(
      LocalStorage.UnitsColumnsVisibilitySettings,
      JSON.stringify({
        [user.id]: newColumnVisibilityState,
      }),
    );
  };

  const columns = useMemo(() => {
    const tempColumn = [
      {
        accessorKey: 'code',
        id: 'code',
        header: t('Unit Code'),
        enableHiding: false,
        size: 212,
      },
      {
        accessorKey: 'status',
        id: 'status',
        header: t('Unit Status & Change Time'),
        enableHiding: false,
        size: 292,
        cell: ({ row: { original: record } }) => (
          <UnitsStatusAndChangeTime unit={record} />
        ),
      },
      {
        id: 'taskCode',
        enableHiding: false,
        header: t('Task Code'),
        size: 220,
        cell: ({ row: { original: record } }) => (
          <LinkButton
            as={Link}
            text={record.task?.code || ''}
            state={{
              [tasksTableId]: {
                expanded: {
                  [record.task?.id]: true,
                },
              },
            }}
            to={{
              pathname: `/tasks`,
              search: `search=${encodeURIComponent(record.task?.code)}&page=1`,
            }}
          />
        ),
      },
      {
        accessorKey: 'address',
        enableHiding: false,
        id: 'address',
        header: t('Address'),
        size: 352,
        cell: ({ row: { original: record } }) => {
          if (record.invalidAddress) {
            return (
              <Tooltip text={t('Review and update address on a Task level')}>
                <span className="text-ui-red">{record.address}</span>
              </Tooltip>
            );
          }
          return <span className="break-normal">{record.address}</span>;
        },
      },
      {
        accessorKey: 'date',
        id: 'date',
        header: t('Execution Date'),
        enableHiding: false,
        size: i18n.language === 'en' ? 120 : 160,
        cell: ({ getValue }) => (
          <span>{moment(getValue()).format('DD.MM.YYYY.')}</span>
        ),
      },
      {
        id: 'executionSlot',
        header: t('Execution Slot'),
        enableHiding: false,
        size: i18n.language === 'en' ? 120 : 140,
        cell: ({ row: { original: record } }) => (
          <span>{`${moment(record.timeFrom).format('HH:mm')}-${moment(
            record.timeTo,
          ).format('HH:mm')}`}</span>
        ),
      },
      {
        id: 'shiftNumber',
        accessorKey: 'shiftNumber',
        header: t('Shift'),
        size: i18n.language === 'en' ? 64 : 86,
        cell: ({ getValue }) => <ShiftLabel shiftNumber={getValue()} />,
      },
      {
        id: 'executionWindow',
        header: t('Execution window'),
        size: i18n.language === 'en' ? 138 : 160,
        cell: ({ row: { original: record } }) => (
          <span>
            {record.latestTour?.tourStop?.windowFrom &&
            record.latestTour?.tourStop?.windowTo
              ? `${moment(record.latestTour.tourStop.windowFrom).format(
                  'HH:mm',
                )}-${moment(record.latestTour.tourStop.windowTo).format('HH:mm')}`
              : 'N/A'}
          </span>
        ),
      },
      {
        accessorKey: 'createdAt',
        id: 'importDate',
        header: t('Import Date'),
        size: 120,
        cell: ({ getValue }) => (
          <span>{moment(getValue()).format('DD.MM.YYYY.')}</span>
        ),
      },
      {
        id: 'tourStop',
        header: t('Tour stop'),
        size: 188,
        cell: TourCell,
      },
      {
        accessorKey: 'hub.shortCode',
        id: 'hub',
        header: t('Hub'),
        size: 72,
      },
      {
        accessorKey: 'number',
        id: 'unitNumber',
        header: t('Unit number'),
        size: i18n.language === 'en' ? 112 : 130,
        cell: ({ row: { original: record } }) => (
          <span>{`${record.number}/${record.unitsInTask}`}</span>
        ),
        meta: {
          visibilityDrawerTooltip: t(
            'The unit number displays the ordinal position of the unit within the task',
          ),
        },
      },
      {
        id: 'client',
        header: t('Client'),
        size: 158,
        cell: ({ row: { original: record } }) => (
          <span>{`${record.client?.name || 'N/A'}`}</span>
        ),
      },
      {
        id: 'endCustomer',
        header: t('Customer'),
        size: 212,
        cell: ({ row: { original: record } }) => {
          const title = `${record.firstName} ${record.lastName}`;
          return (
            <EndCustomerPopover
              title={title}
              phoneNumber={record.phoneNumber}
              email={record.email}
              key={record.id}
            >
              <LinkButton className="text-left" text={title} />
            </EndCustomerPopover>
          );
        },
      },
      {
        id: 'actions',
        size: 122,
        cell: (cellProps) => (
          <UnitsTableActionsCell
            {...cellProps}
            onUpdateUnitCodeClick={setUnitForCodeUpdate}
            onUpdateUnitStatus={setUnitForStatusUpdate}
            onUnitDetailsClick={setUnitForDetailsView}
            onUnitCancel={setUnitForCodeCancel}
          />
        ),
        enableHiding: false,
        header: () => (
          <UnitsTableHeaderActions
            onShowHideColumnsClick={() => setIsDrawerOpen(true)}
          />
        ),
        meta: {
          padding: CellPadding.Sm,
          align: CellAlignment.Right,
          showInVisibilityToggle: false,
        },
      },
    ];

    if (isSelectionVisible) {
      return tempColumn.filter((column) => column.id !== 'actions');
    }

    return tempColumn;
  }, [t, i18n.language, isSelectionVisible]);

  const getHiddenColumnsByDefault = useMemo(() => {
    const defaultColumns = {};
    columns.forEach((column) => {
      if (column.enableHiding === undefined) {
        defaultColumns[column.id] = false;
      }
    });
    return defaultColumns;
  }, [columns]);

  const isLocalStorageEmpty = useMemo(() => {
    if (
      !columnVisibilityState ||
      Object.keys(columnVisibilityState[user.id] || {}).length === 0
    ) {
      return true;
    }

    return false;
  }, [columnVisibilityState, user.id]);

  const onDrawerClose = useCallback(() => setIsDrawerOpen(false), []);

  return (
    <>
      <UnitsTableFilters count={total} />
      <UpdateUnitCode
        isOpen={!!unitForCodeUpdate}
        unit={unitForCodeUpdate}
        onClose={() => setUnitForCodeUpdate(null)}
      />
      <CancelUnitModal
        isOpen={!!unitForCodeCancel}
        unit={unitForCodeCancel}
        onClose={() => setUnitForCodeCancel(null)}
      />
      <UnitStatusUpdate
        isOpen={!!unitForStatusUpdate}
        units={[unitForStatusUpdate]}
        onClose={() => setUnitForStatusUpdate(null)}
      />
      <UnitDetailsDrawer
        isOpen={!!unitForDetailsView}
        unitId={unitForDetailsView?.id}
        onClose={() => setUnitForDetailsView(null)}
      />
      <NewTable
        id="units-table"
        restoreScroll
        inlineScroll
        minColSize={64}
        roundedTop={false}
        data={data}
        columns={columns}
        enableSelection={isSelectionVisible}
        columnVisibilityState={
          isLocalStorageEmpty
            ? getHiddenColumnsByDefault
            : columnVisibilityState[user.id]
        }
        isDrawerOpen={isDrawerOpen}
        onDrawerClose={onDrawerClose}
        onColumnVisibilityChange={onColumnVisibilityChange}
        {...rest}
      />
    </>
  );
};

UnitsTable.propTypes = {
  total: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      status: PropTypes.oneOf(Object.values(DeliveryTaskUnitStatus)),
      statusChangeTime: PropTypes.string,
      address: PropTypes.string,
      invalidAddress: PropTypes.bool,
      date: PropTypes.string,
      timeFrom: PropTypes.string,
      timeTo: PropTypes.string,
      shiftNumber: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
      latestTour: PropTypes.shape({
        id: PropTypes.string,
        vehicle: PropTypes.string,
      }),
      unitsInTask: PropTypes.number,
      hub: PropTypes.shape({
        id: PropTypes.string,
        shortCode: PropTypes.string,
      }),
      client: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      createdAt: PropTypes.string,
      number: PropTypes.number,
    }),
  ),
};

UnitsTable.defaultProps = {
  data: [],
  total: undefined,
};

export default UnitsTable;
