import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Icon from '../Icon';

const Input = forwardRef((props, ref) => {
  const {
    className,
    clearable,
    disabled,
    hasError,
    icon,
    iconFocusColor,
    inlineLabel,
    isFullWidth,
    onChange,
    value,
    ...rest
  } = props;

  const showClearIcon = clearable && value && value.length > 0;

  return (
    <div className={cn('relative flex items-center', isFullWidth && 'w-full')}>
      <input
        className={cn(
          'peer h-10 w-full rounded-md border border-grey-500 px-3 text-sm text-primary-dark outline-none placeholder:text-grey-700 focus:border-primary-yellow disabled:bg-grey-200 disabled:opacity-40',
          !disabled && hasError && 'border-ui-red',
          icon && 'pl-8',
          showClearIcon && 'pr-8',
          className,
        )}
        disabled={disabled}
        ref={ref}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {icon && (
        <div
          className={cn(
            'absolute left-2',
            `peer-focus:text-${iconFocusColor}`,
            disabled && 'opacity-40',
          )}
        >
          <Icon className="h-4 w-4" icon={icon} />
        </div>
      )}
      {showClearIcon && (
        <Icon
          aria-label="clear"
          className={cn(
            'absolute right-[11px] h-4 w-4 peer-focus:text-primary-yellow text-grey-700',
            disabled && 'opacity-40',
          )}
          icon="xCircleFilled"
          onClick={() => {
            onChange({ target: { value: '' } });
            if (ref?.current?.focus) {
              ref.current.focus();
            }
          }}
        />
      )}
      {inlineLabel && !showClearIcon && !icon && (
        <span
          className={cn(
            'absolute right-3 text-sm pointer-events-none',
            disabled && 'opacity-40',
          )}
        >
          {inlineLabel}
        </span>
      )}
    </div>
  );
});

Input.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  icon: PropTypes.string,
  iconFocusColor: PropTypes.string,
  isFullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  inlineLabel: PropTypes.string,
};

Input.defaultProps = {
  className: '',
  clearable: false,
  disabled: false,
  hasError: false,
  icon: undefined,
  iconFocusColor: 'primary-yellow',
  isFullWidth: false,
  onChange: () => {},
  value: undefined,
  inlineLabel: undefined,
};

export default Input;
