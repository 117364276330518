import { useTranslation } from 'react-i18next';

import eventType from '../eventType';
import NoteField from './NoteField';
import UserField from './UserField';

const AddressChangeEvent = (props) => {
  const { event } = props;
  const { t } = useTranslation();
  return (
    <>
      {event.newAddressDetails && (
        <div>
          <span>{t('Full address')}: </span>
          <span className="font-medium">{event.newAddressDetails}</span>
        </div>
      )}
      {event.note && <NoteField note={event.note} />}
      {event.oldAddressDetails && (
        <div>
          <span>{t('Previous full address')}: </span>
          <span className="font-medium">{event.oldAddressDetails}</span>
        </div>
      )}

      <UserField event={event} />
    </>
  );
};

AddressChangeEvent.propTypes = {
  event: eventType.isRequired,
};

export default AddressChangeEvent;
