import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { Card } from '../../../components/Card';
import ErrorPage from '../../../components/ErrorPage';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import useFetch from '../../../lib/api/hooks/useFetch';
import DeliveryOption from '../DeliveryOption/DeliveryOption';
import AlternativeDeliveryOptionModal from './AlternativeDeliveryOptionModal';

const AlternativeDeliveryOptions = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [openedDeliveryOptionId, setOpenedDeliveryOptionId] = useState(null);

  const { fetch } = useFetch();
  const {
    data,
    error,
    isError,
    isLoading: isOrganisationLoading,
  } = useQuery([`/clients/`, id], async () => {
    const response = await fetch(`/clients/${id}`, {
      method: 'GET',
    });
    return response.json();
  });

  const deliveryOptionUrl = `/delivery-options?clientId=${id}`;

  const {
    data: deliveryOptionsData,
    error: deliveryOptionsError,
    isError: isDeliveryOptionsError,
    isLoading: isDeliveryOptionsLoading,
  } = useQuery([deliveryOptionUrl], async () => {
    const response = await fetch(deliveryOptionUrl, {
      method: 'GET',
    });
    return response.json();
  });

  const organisation = data?.data || null;
  const deliveryOptions = deliveryOptionsData?.data || null;

  const selectedDeliveryOption = useMemo(() => {
    if (!deliveryOptions || deliveryOptions?.length === 0) {
      return undefined;
    }

    return deliveryOptions?.find(
      (option) => option.id === openedDeliveryOptionId,
    );
  }, [openedDeliveryOptionId, deliveryOptions]);

  if (isError || isDeliveryOptionsError) {
    return <ErrorPage error={error || deliveryOptionsError} />;
  }

  return (
    <>
      <AlternativeDeliveryOptionModal
        clientId={id}
        deliveryOption={selectedDeliveryOption}
        isOpen={!!selectedDeliveryOption}
        onClose={() => setOpenedDeliveryOptionId(null)}
      />
      <Page>
        <Titlebar
          backLink={`/clients/${id}`}
          textPrimary={t('Alternative Delivery Options Configuration')}
          textSecondary={organisation?.name}
          isLoading={isOrganisationLoading}
        />
        <Page.Content variant="grey">
          {isDeliveryOptionsLoading ? (
            <div className="grid gap-4">
              <Skeleton wrapper={Card} height={84} />
              <Skeleton wrapper={Card} height={84} />
              <Skeleton wrapper={Card} height={84} />
            </div>
          ) : (
            <div>
              <div className="mb-[18px] text-primary-dark">
                {t(
                  'Enable the alternative safe delivery options for Drivers and determine whether options require customer consent',
                )}
              </div>
              <div className="flex flex-col gap-4" data-test="delivery-options">
                {deliveryOptions?.map((option) => (
                  <DeliveryOption
                    key={option.id}
                    deliveryOption={option}
                    onButtonClick={(value) => setOpenedDeliveryOptionId(value)}
                  />
                ))}
              </div>
            </div>
          )}
        </Page.Content>
      </Page>
    </>
  );
};

export default AlternativeDeliveryOptions;
