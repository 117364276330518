import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LoadingSpinner from '../../../components/LoadingSpinner';
import Modal from '../../../components/Modal';
import useCustomToast from '../../../hooks/useCustomToast';
import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import AlternativeDeliveryOptionForm from './AlternativeDeliveryOptionForm';

const AlternativeDeliveryOptionModal = ({
  clientId,
  deliveryOption,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const { fetch } = useFetch();
  const queryClient = useQueryClient();
  const { nameTranslations } = useDeliveryOptionsTranslations();

  const mutation = useMutation(
    (values) => {
      if (deliveryOption.enabled && values.enabled) {
        const body = {
          requiredConsent: values.requiredConsent,
        };
        return fetch(
          `/clients/${clientId}/delivery-options/${deliveryOption.id}`,
          {
            body,
            method: 'PUT',
          },
        );
      }

      if (!deliveryOption.enabled && values.enabled) {
        const body = {
          deliveryOptionId: deliveryOption.id,
          requiredConsent: values.requiredConsent,
        };
        return fetch(`/clients/${clientId}/delivery-options`, {
          body,
          method: 'POST',
        });
      }

      if (deliveryOption.enabled && !values.enabled) {
        return fetch(
          `/clients/${clientId}/delivery-options/${deliveryOption.id}`,
          {
            method: 'DELETE',
          },
        );
      }

      return null;
    },
    {
      onError: (error) => {
        toastFetchError(error);
        onClose();
      },
      onSuccess: () => {
        toastSuccess(t('Successfully updated delivery option.'));
        queryClient.invalidateQueries([
          `/delivery-options?clientId=${clientId}`,
        ]);
        onClose();
      },
    },
  );

  return (
    <Modal
      data-test="delivery-option-modal"
      body={
        deliveryOption ? (
          <AlternativeDeliveryOptionForm
            onClose={onClose}
            onSubmit={mutation.mutate}
            deliveryOption={deliveryOption}
          />
        ) : (
          <LoadingSpinner className="h-4 w-4 self-center" />
        )
      }
      header={
        <div className="flex items-center gap-2">
          <span>
            {t('Alternative safe delivery')} -{' '}
            {nameTranslations[deliveryOption?.code]}
          </span>
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

AlternativeDeliveryOptionModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deliveryOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    requiredConsent: PropTypes.bool.isRequired,
    requiredProof: PropTypes.string.isRequired,
    requiredNote: PropTypes.bool,
    enabled: PropTypes.bool,
  }),
};

AlternativeDeliveryOptionModal.defaultProps = {
  deliveryOption: undefined,
};

export default AlternativeDeliveryOptionModal;
