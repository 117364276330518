import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DatePicker from '../DatePicker';
import InputLayout from '../InputLayout';

const FormDateRangePicker = (props) => {
  const { id, label, name, placeholderText, required, ...rest } = props;

  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext();

  const { t } = useTranslation();

  const placeholder = placeholderText ?? t('Start Date -> End Date');

  const generateValidators = useCallback(() => {
    const validators = {};
    if (required) {
      validators.validate = (value) =>
        (value[0] !== null && value[1] !== null) ||
        t('This field is required.');
    }

    return validators;
  }, [required, t]);

  const error = errors[name] && errors[name].message;

  return (
    <InputLayout error={error} id={id} label={label} required={required}>
      <Controller
        contorl={control}
        name={name}
        render={({ field: { onChange, value, ...controllerProps } }) => (
          <DatePicker
            {...rest}
            endDate={value[1]}
            error={!!error}
            placeholderText={placeholder}
            selectsRange
            startDate={value[0]}
            value={undefined}
            onCalendarClose={() => {
              trigger(name);
            }}
            onChange={(val) => {
              onChange(val);
              if (
                (val[0] !== null && val[1] !== null) ||
                (val[0] === null && val[1] === null)
              ) {
                trigger(name);
              }
            }}
            {...controllerProps}
          />
        )}
        rules={generateValidators()}
      />
    </InputLayout>
  );
};

FormDateRangePicker.propTypes = {
  dateFormat: PropTypes.string,
  id: PropTypes.string,
  isClearable: PropTypes.bool,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
};

FormDateRangePicker.defaultProps = {
  dateFormat: 'dd.MM.yyyy',
  id: undefined,
  isClearable: false,
  label: undefined,
  minDate: undefined,
  name: '',
  onChange: undefined,
  placeholderText: undefined,
  required: false,
  value: undefined,
};

export default FormDateRangePicker;
