import { useCallback } from 'react';

import useApiErrorTranslation from './useApiErrorTranslation';
import useCustomToast from './useCustomToast';

const useToastFetchError = () => {
  const { toastError } = useCustomToast();
  const { translateError } = useApiErrorTranslation();

  const toastFetchError = useCallback(
    (err) => {
      if (err?.status === 503 && err?.data?.maintenance === true) {
        // this will be handled by maintenance mode in Root.jsx
        return;
      }

      const errorMessage = translateError(err);
      toastError(errorMessage);
    },
    [toastError, translateError],
  );

  return { toastFetchError };
};

export default useToastFetchError;
