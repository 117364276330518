import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import useInfiniteSelect from '../../hooks/useInfiniteSelect';
import FormSelect from '../FormSelect';

const InfiniteScrollFormSelect = (props) => {
  const {
    defaultValue,
    disabled,
    error,
    id,
    itemsPerPage,
    label,
    name,
    onChange,
    placeholder,
    readOnly,
    required,
    searchKey,
    singleItemUrl,
    transformOptionFn,
    url,
    useLocalSearch,
    ...rest
  } = props;

  const { watch } = useFormContext();
  const value = watch(name);

  const {
    isFetching,
    mountedValueIsFetching,
    onBottomReached,
    options,
    setSearchValue,
  } = useInfiniteSelect({
    itemsPerPage,
    searchKey,
    singleItemUrl,
    transformOptionFn,
    url,
    value,
  });

  return (
    <FormSelect
      id={id}
      label={label}
      defaultValue={defaultValue}
      options={options}
      disabled={disabled}
      error={error}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      useLocalSearch={useLocalSearch}
      name={name}
      isLoading={isFetching || mountedValueIsFetching}
      onBottomReached={onBottomReached}
      onChange={onChange}
      onSearch={(searchVal) => {
        if (useLocalSearch) {
          return;
        }
        setSearchValue(searchVal);
      }}
      {...rest}
    />
  );
};

InfiniteScrollFormSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  searchKey: PropTypes.string,
  url: PropTypes.string.isRequired,
  transformOptionFn: PropTypes.func,
  singleItemUrl: PropTypes.string,
  useLocalSearch: PropTypes.bool,
  onChange: PropTypes.func,
};

InfiniteScrollFormSelect.defaultProps = {
  defaultValue: '',
  disabled: false,
  error: false,
  id: '',
  label: '',
  name: '',
  placeholder: '',
  itemsPerPage: 50,
  readOnly: false,
  required: false,
  searchKey: 'name',
  transformOptionFn: (option) => ({ value: option.id, label: option.id }),
  singleItemUrl: undefined,
  useLocalSearch: false,
  onChange: undefined,
};

export default InfiniteScrollFormSelect;
