import { useCallback } from 'react';
import { toast } from 'react-toastify';

import Icon from '../components/Icon';

const useCustomToast = () => {
  const toastSuccess = useCallback(
    (message, options = {}) =>
      toast.success(message, {
        icon: (
          <Icon
            data-test="toast-icon"
            className="h-4 w-4"
            icon="checkmarkCircleFilled"
          />
        ),
        ...options,
      }),
    [],
  );

  const toastError = useCallback(
    (message, options = {}) =>
      toast.error(message, {
        icon: <Icon data-test="toast-icon" className="h-4 w-4" icon="report" />,
        ...options,
      }),
    [],
  );

  const toastWarning = useCallback(
    (message, options = {}) =>
      toast.warning(message, {
        icon: (
          <Icon data-test="toast-icon" className="h-4 w-4" icon="warning" />
        ),
        ...options,
      }),
    [],
  );

  const toastInfo = useCallback(
    (message, options = {}) =>
      toast.info(message, {
        icon: (
          <Icon data-test="toast-icon" className="h-4 w-4" icon="infoFilled" />
        ),
        ...options,
      }),
    [],
  );

  return { toastError, toastInfo, toastSuccess, toastWarning };
};

export default useCustomToast;
