import moment from 'moment';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useDateQuery = () => {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    const date = searchParams.get('date');

    if (moment(date).isValid()) {
      return new Date(date);
    }

    return undefined;
  }, [searchParams]);
};

export default useDateQuery;
