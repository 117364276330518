const DeliveryOption = {
  Direct: 'direct',
  Neighbour: 'neighbour',
  FrontDoor: 'frontDoor',
  ApartmentDoor: 'apartmentDoor',
  Garage: 'garage',
  CustomArrangement: 'customArrangement',
};

export default DeliveryOption;
