const UnitEventType = {
  Import: 'import',
  StatusChange: 'statusChange',
  TimeSlotChange: 'timeSlotChange',
  AddressChange: 'addressChange',
  TrackingTimeSlotChange: 'trackingTimeSlotChange',
  FailedAttempt: 'failedAttempt',
  Postponement: 'postponement',
  ReferenceUpdate: 'referenceUpdate',
  Refused: 'refused',
  Success: 'success',
  Routing: 'routing',
  Delay: 'delay',
  HubScan: 'hubScan',
  DriverScan: 'driverScan',
  Found: 'found',
  Fixed: 'fixed',
  Note: 'note',
  Cancellation: 'cancellation',
  WrongDriverScan: 'wrongDriverScan',
};

export default UnitEventType;
