import { useTranslation } from 'react-i18next';

const useDeliveryOptionsTranslations = () => {
  const { t } = useTranslation();

  const directDeliveryOptionName = t('deliveryOption:direct:name');
  const frontDoorDeliveryOptionName = t('deliveryOption:frontDoor:name');
  const apartmentDoorDeliveryOptionName = t(
    'deliveryOption:apartmentDoor:name',
  );
  const garageDeliveryOptionName = t('deliveryOption:garage:name');
  const neighbourDeliveryOptionName = t('deliveryOption:neighbour:name');
  const customArrangementDeliveryOptionName = t(
    'deliveryOption:customArrangement:name',
  );

  const frontDoorDeliveryOptionDescription = t(
    'deliveryOption:frontDoor:description',
  );
  const apartmentDoorDeliveryOptionDescription = t(
    'deliveryOption:apartmentDoor:description',
  );
  const garageDeliveryOptionDescription = t(
    'deliveryOption:garage:description',
  );
  const neighbourDeliveryOptionDescription = t(
    'deliveryOption:neighbour:description',
  );
  const customArrangementDeliveryOptionDescription = t(
    'deliveryOption:customArrangement:description',
  );

  const nameTranslations = {
    direct: directDeliveryOptionName,
    apartmentDoor: apartmentDoorDeliveryOptionName,
    frontDoor: frontDoorDeliveryOptionName,
    garage: garageDeliveryOptionName,
    neighbour: neighbourDeliveryOptionName,
    customArrangement: customArrangementDeliveryOptionName,
  };

  const descriptionTranslations = {
    apartmentDoor: apartmentDoorDeliveryOptionDescription,
    frontDoor: frontDoorDeliveryOptionDescription,
    garage: garageDeliveryOptionDescription,
    neighbour: neighbourDeliveryOptionDescription,
    customArrangement: customArrangementDeliveryOptionDescription,
  };

  return { nameTranslations, descriptionTranslations };
};

export default useDeliveryOptionsTranslations;
