import PropTypes from 'prop-types';

const unitPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  attempt: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  statusNote: PropTypes.string,
  code: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  reference: PropTypes.string,
  statusChangeTime: PropTypes.string.isRequired,
});

export default unitPropType;
