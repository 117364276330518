import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import CreateMemberForm from '../../features/members/CreateMemberForm';
import useFetch from '../../lib/api/hooks/useFetch';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationNewMember = () => {
  const { user } = useUser();
  const { fetch } = useFetch();

  const {
    data: fetchedOrganisation,
    error,
    isError,
  } = useQuery([`/carriers/${user.carrier.id}`], async () => {
    const response = await fetch(`/carriers/${user.carrier.id}`);
    return response.json();
  });

  const organisation = fetchedOrganisation?.data || null;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (!organisation) {
    return null;
  }

  if (redirect) {
    return <Navigate replace to="/my-organisation/members" />;
  }

  const title = t('New Member');
  return (
    <Page>
      <Titlebar
        icon={<Icon className="h-5 w-5 text-ui-blue" icon="userAdd" />}
        textPrimary={title}
        titleRightContent={
          <Button
            className="w-full lg:w-fit"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={() => navigate('/my-organisation/members')}
          />
        }
      />
      <Page.Content centerContent variant="grey">
        <div className="w-full max-w-[480px]">
          <Card className="px-4 py-5 sm:p-8 sm:pt-6">
            <CreateMemberForm
              organisationId={organisation.id}
              organisationName={organisation.name}
              organisationType={organisation.type}
              onCancel={() => {
                navigate('/my-organisation/members');
              }}
              onSuccess={() => {
                setRedirect(true);
              }}
              isSubcarrierInitiallyChecked
            />
          </Card>
        </div>
      </Page.Content>
    </Page>
  );
};

export default MyOrganisationNewMember;
