import cn from 'classnames';
import PropTypes from 'prop-types';

const Switch = (props) => {
  const { checked, disabled, onChange } = props;

  return (
    <button
      className={cn(
        'relative flex h-6 w-10 flex-shrink-0 items-center rounded-xl transition-all disabled:opacity-40',
        checked ? 'bg-ui-blue' : 'bg-grey-500',
      )}
      disabled={disabled}
      type="button"
      onClick={() => onChange(!checked)}
    >
      <div
        className={cn(
          'absolute m-0.5 h-5 w-5 rounded-full bg-white transition-all',
          checked ? 'left-4' : 'left-0',
        )}
      />
    </button>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Switch.defaultProps = {
  disabled: false,
};

export default Switch;
