import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Drawer from '../../../components/Drawer';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import parseSearchParams from '../../../helpers/parseSearchParams';
import useUnitsPageSearchParams from '../../../hooks/useUnitsPageSearchParams';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import ClientSelect from '../../shared/ClientSelect';
import HubSelect from '../../shared/HubSelect';
import ShiftsSelect from '../../shared/ShiftsSelect';
import UnitProgressAndStatusSelect from '../UnitProgressAndStatusSelect';
import UnitsSearch from '../UnitsSearch';

const UnitsFiltersDrawer = (props) => {
  const { isOpen, onClose } = props;
  const {
    currentSortType,
    searchParams: currentSearchParams,
    setSearchParams,
  } = useUnitsPageSearchParams();
  const { isSelectionVisible, selectedItems } = useListSelection();
  const isSelectionActive = selectedItems.length > 0;
  const prevIsOpen = usePrevious(isOpen);
  const { t } = useTranslation();
  const [params, setParams] = useState(parseSearchParams(currentSearchParams));

  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      setParams(parseSearchParams(currentSearchParams));
    }
  }, [currentSearchParams, isOpen, prevIsOpen]);

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      const newParams = { ...params };

      newParams.page = 1;
      if (!queryValue) {
        delete newParams[queryKey];
      } else {
        newParams[queryKey] = queryValue;
      }
      setParams(newParams);
    },
    [params],
  );

  const [searchInputKey, setSearchInputKey] = useState(new Date().toString());

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      header={t('Filters')}
      footer={
        <Button
          data-test="modal-button-action"
          text={t('Save')}
          variant="solidBlue"
          isFullWidth
          onClick={() => {
            const urlParams = new URLSearchParams(params);
            setSearchParams(urlParams);
            onClose();
          }}
        />
      }
      body={
        <div className="flex flex-col gap-4">
          <UnitsSearch
            key={searchInputKey}
            debounceMs={0}
            searchBy={params.searchBy}
            searchValue={params.search}
            onSearch={(newSearchValue) => {
              const newParams = { ...params };
              if (newSearchValue === params.search || !params.searchBy) {
                return;
              }
              newParams.page = 1;
              if (!newSearchValue) {
                delete newParams.search;
              } else {
                newParams.search = newSearchValue?.trim();
                newParams.searchBy = params.searchBy;
              }
              setParams(newParams);
            }}
            onSearchByChange={(newSearchBy) => {
              const newParams = { ...params };
              newParams.page = 1;
              newParams.searchBy = newSearchBy;
              setParams(newParams);
            }}
          />

          <div className="border-b border-grey-200" />

          <ClientSelect
            value={params.clientId}
            onChange={(clientId) => {
              onSelectChange('clientId', clientId);
            }}
          />

          <HubSelect
            value={params.hubId}
            onChange={(hubId) => {
              onSelectChange('hubId', hubId);
            }}
          />

          <UnitProgressAndStatusSelect
            isSingleDropdown
            allowProgressOnlySelection={!isSelectionVisible}
            disabled={isSelectionActive}
            unitProgress={params.progress}
            unitStatus={params.status}
            deliveryOption={params.deliveryOption}
            failureReason={params.failureReason}
            postponeReason={params.postponeReason}
            returnReason={params.returnReason}
            onChange={(value) => {
              const newParams = { ...params };
              newParams.page = 1;

              if (!value) {
                delete newParams.progress;
                delete newParams.status;
                delete newParams.deliveryOption;
                delete newParams.failureReason;
                delete newParams.postponeReason;
                delete newParams.returnReason;

                setParams(newParams);
                return;
              }

              const [
                unitProgressValue = '',
                unitStatusValue = '',
                unitAdditionalOptionValue = '',
              ] = value?.split('-') || [];

              newParams.progress = unitProgressValue;
              newParams.status = unitStatusValue;

              delete newParams.failureReason;
              delete newParams.postponeReason;
              delete newParams.returnReason;
              delete newParams.deliveryOption;

              if (unitStatusValue === DeliveryTaskUnitStatus.Delivered) {
                newParams.deliveryOption = unitAdditionalOptionValue;
              }

              if (unitStatusValue === DeliveryTaskUnitStatus.Retry) {
                newParams.failureReason = unitAdditionalOptionValue;
              }

              if (unitStatusValue === DeliveryTaskUnitStatus.Postponed) {
                newParams.postponeReason = unitAdditionalOptionValue;
              }

              if (unitStatusValue === DeliveryTaskUnitStatus.ReturnToClient) {
                newParams.returnReason = unitAdditionalOptionValue;
              }

              setParams(newParams);
            }}
          />

          <ShiftsSelect
            value={params.shiftNumber}
            onChange={(value) => {
              const newParams = { ...params };
              newParams.page = 1;
              if (!value) {
                delete newParams.shiftNumber;
              } else {
                newParams.shiftNumber = value;
              }

              setParams(newParams);
            }}
          />

          <DatePicker
            disabled={isSelectionActive}
            dateFormat="dd.MM.yyyy"
            isClearable
            name="date"
            placeholderText={t('Date')}
            required
            value={params.date ? new Date(params.date) : undefined}
            onChange={(date) => {
              const formattedDate = date && moment(date).format('YYYY-MM-DD');
              onSelectChange('date', formattedDate);
            }}
          />

          <Button
            isFullWidth
            variant="outlineBlue"
            text={t('Clear All')}
            onClick={() => {
              setSearchInputKey(new Date().toString());
              const newParams = {
                page: 1,
                sortType: currentSortType,
                searchBy: params.searchBy,
                search: params.search,
              };

              setParams(newParams);
            }}
          />
        </div>
      }
    />
  );
};

UnitsFiltersDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

UnitsFiltersDrawer.defaultProps = {
  isOpen: false,
  onClose: undefined,
};

export default UnitsFiltersDrawer;
