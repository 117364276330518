import cn from 'classnames';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputLayout from '../../../components/InputLayout';
import ListChoice from '../../../components/ListChoice';
import Textarea from '../../../components/Textarea';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import PostponeReason from '../../../enums/PostponeReason';
import useFormInputValidationRules from '../../../hooks/useFormInputValidationRules';
import usePostponeReasonTranslations from '../../../hooks/usePostponeReasonTranslations';
import ChangeUnitStatusContext from './ChangeUnitStatusContext';

const PostponedReasons = () => {
  const postponedResonsTranslations = usePostponeReasonTranslations();
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useFormContext();
  const { t } = useTranslation();
  const status = watch('status');
  const postponeReason = watch('postponeReason');
  const changeUnitStatusContext = useContext(ChangeUnitStatusContext);

  const { availablePostponeReasons = [], defaultValues = {} } =
    changeUnitStatusContext;
  const { postponeReason: originalPostponeReason, status: originalStatus } =
    defaultValues;
  const isOriginalStatusPostpone =
    originalStatus === DeliveryTaskUnitStatus.Postponed;

  const options = [originalPostponeReason, ...availablePostponeReasons].filter(
    (option) => option,
  );

  const rules = useFormInputValidationRules({
    required: true,
  });

  const errorInput =
    errors.postponeReasonNote && errors.postponeReasonNote.message;

  return (
    <div className="flex">
      <div className="mx-3 border-l-[1px] border-grey-300" />
      <div className="flex-1">
        <Controller
          name="postponeReason"
          render={({ field: { onChange: onPostponeChange } }) =>
            options.map((availablePostponeReason) => {
              const isSelected =
                status === DeliveryTaskUnitStatus.Postponed &&
                postponeReason === availablePostponeReason;

              const isOriginalPostponeReason =
                originalPostponeReason === availablePostponeReason;
              const isOriginal =
                isOriginalStatusPostpone && isOriginalPostponeReason;

              return (
                <div
                  className={cn(
                    isOriginalStatusPostpone &&
                      !isOriginalPostponeReason &&
                      'opacity-40 pointer-events-none',
                  )}
                  key={availablePostponeReason}
                >
                  <ListChoice
                    isSelected={isSelected}
                    onClick={() => {
                      if (isOriginal) {
                        reset();
                        return;
                      }

                      if (isSelected) {
                        return;
                      }

                      onPostponeChange(availablePostponeReason);
                      setValue('deliveryOptionNote', '');
                      setValue('deliveryPostponeNote', '');
                      setValue('failureReason', '');
                      setValue('notifyCustomer', false);
                      setValue('statusNote', '');
                      setValue('unitSpecialCase', '');
                      setValue('returnReason', '');
                      setValue('status', DeliveryTaskUnitStatus.Postponed, {
                        shouldDirty: true,
                      });
                    }}
                  >
                    <span className="text-sm pl-2 text-primary-dark">
                      {postponedResonsTranslations[availablePostponeReason]}
                    </span>
                  </ListChoice>
                  {postponeReason === availablePostponeReason &&
                    postponeReason === PostponeReason.ForceMajeure &&
                    status === DeliveryTaskUnitStatus.Postponed && (
                      <div className="mt-2">
                        <Controller
                          name="postponeReasonNote"
                          control={control}
                          rules={rules()}
                          render={({ field }) => (
                            <InputLayout
                              label={t('Details')}
                              required
                              error={errorInput}
                            >
                              <Textarea
                                hasError={!!errorInput}
                                isReadOnly={
                                  !isSelected || isOriginalPostponeReason
                                }
                                autoFocus
                                className="resize-none"
                                data-test="deliveryRemarkNote"
                                placeholder={t('Justification')}
                                {...field}
                              />
                            </InputLayout>
                          )}
                        />
                      </div>
                    )}
                </div>
              );
            })
          }
        />
      </div>
    </div>
  );
};

export default PostponedReasons;
