import cn from 'classnames';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import ListChoice from '../../../components/ListChoice';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import useFailureReasonTranslations from '../../../hooks/useFailureReasonTranslations';
import ChangeUnitStatusContext from './ChangeUnitStatusContext';

const FailureReasons = () => {
  const failureResonsTranslations = useFailureReasonTranslations();
  const { reset, setValue, watch } = useFormContext();
  const status = watch('status');
  const failureReason = watch('failureReason');
  const changeUnitStatusContext = useContext(ChangeUnitStatusContext);

  const { availableFailureReasons = [], defaultValues = {} } =
    changeUnitStatusContext;
  const { failureReason: originalFailureReason, status: originalStatus } =
    defaultValues;
  const isOriginalStatusRetry = originalStatus === DeliveryTaskUnitStatus.Retry;

  const options = [originalFailureReason, ...availableFailureReasons].filter(
    (option) => option,
  );

  return (
    <div className="flex">
      <div className="mx-3 border-l-[1px] border-grey-300" />
      <div className="flex-1">
        <Controller
          name="failureReason"
          render={({ field: { onChange: onFailureReasonChange } }) =>
            options.map((availableFailureReason) => {
              const isSelected =
                status === DeliveryTaskUnitStatus.Retry &&
                failureReason === availableFailureReason;

              const isOriginalFailureReason =
                originalFailureReason === availableFailureReason;
              const isOriginal =
                isOriginalStatusRetry && isOriginalFailureReason;

              return (
                <div
                  className={cn(
                    isOriginalStatusRetry &&
                      !isOriginalFailureReason &&
                      'opacity-40 pointer-events-none',
                  )}
                  key={availableFailureReason}
                >
                  <ListChoice
                    isSelected={isSelected}
                    onClick={() => {
                      if (isOriginal) {
                        reset();
                        return;
                      }

                      if (isSelected) {
                        return;
                      }

                      onFailureReasonChange(availableFailureReason);
                      setValue('deliveryOptionNote', '');
                      setValue('deliveryOption', '');
                      setValue('postponeReason', '');
                      setValue('postponeReasonNote', '');
                      setValue('notifyCustomer', false);
                      setValue('statusNote', '');
                      setValue('unitSpecialCase', '');
                      setValue('returnReason', '');
                      setValue('status', DeliveryTaskUnitStatus.Retry, {
                        shouldDirty: true,
                      });
                    }}
                  >
                    <span className="text-sm pl-2 text-primary-dark">
                      {failureResonsTranslations[availableFailureReason]}
                    </span>
                  </ListChoice>
                </div>
              );
            })
          }
        />
      </div>
    </div>
  );
};

export default FailureReasons;
