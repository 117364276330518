import { useTranslation } from 'react-i18next';

import Tooltip from '../../../components/Tooltip';
import UnitEventType from '../../../enums/UnitEventType';
import useBorderBoxSize from '../../../hooks/useBorderBoxSize';
import eventType from './eventType';

const EventLabel = (props) => {
  const { event } = props;
  const { t } = useTranslation();
  let text;

  const [spanRef, spanSize] = useBorderBoxSize();
  const [duplicateInivisibleSpanRef, duplicateInivisibleSpanSize] =
    useBorderBoxSize();
  const isOverflowing =
    duplicateInivisibleSpanSize?.inlineSize > spanSize?.inlineSize;

  const eventTypeTranslations = {
    [UnitEventType.Import]: t('Import'),
    [UnitEventType.Found]: t('Found'),
    [UnitEventType.Fixed]: t('Fixed'),
    [UnitEventType.Note]: t('Unit note added'),
    [UnitEventType.ReferenceUpdate]: t('Unit code updated'),
    [UnitEventType.TimeSlotChange]: t('Execution date and time update'),
    [UnitEventType.TrackingTimeSlotChange]: t('Execution date and time update'),
    [UnitEventType.HubScan]: t('Hub scan'),
    [UnitEventType.Routing]: t('Routing'),
    [UnitEventType.DriverScan]: t('Driver scan'),
    [UnitEventType.Delay]: t('Execution delay'),
    [UnitEventType.Success]: t('Execution success'),
    [UnitEventType.Postponement]: t('Postponement'),
    [UnitEventType.Refused]: t('Refused by customer'),
    [UnitEventType.Cancellation]: t('Canceled'),
    [UnitEventType.AddressChange]: t('Address change'),
    [UnitEventType.WrongDriverScan]: t('Scanned for incorrect Tour'),
  };

  if (event.type === UnitEventType.StatusChange && event.user) {
    text = t('Manual status update');
  }

  if (event.type === UnitEventType.StatusChange && !event.user) {
    text = t('Status update');
  }

  if (event.type === UnitEventType.FailedAttempt) {
    text = event.attempt
      ? t(`${t('Attempt failed')} (${event.attempt})`)
      : t('Attempt failed');
  }

  const finalText = text || eventTypeTranslations[event.type];

  return (
    <Tooltip text={finalText} hidden={!isOverflowing} className="truncate">
      <div className="text-sm text-center rounded-3xl px-3 py-1 font-medium bg-ui-info-blue-light text-grey-900 truncate relative">
        <span
          ref={spanRef}
          className="w-full inline-block overflow-hidden align-middle overflow-ellipsis"
        >
          {finalText}
        </span>
        <span
          ref={duplicateInivisibleSpanRef}
          className="absolute invisible opacity-0"
        >
          {finalText}
        </span>
      </div>
    </Tooltip>
  );
};

EventLabel.propTypes = {
  event: eventType.isRequired,
};

export default EventLabel;
