import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import FormAddressInput from '../../../components/FormAddressInput';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import Icon from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import OrganisationType from '../../../enums/OrganisationType';
import ShortcodeInput from '../ShortcodeInput';

const OrganisationForm = (props) => {
  const {
    errorCode,
    errors,
    isClient,
    isEdit,
    isSubmitting,
    onCancel,
    onIsDirtyChange,
    onSubmit,
    organisation = {},
  } = props;

  const { t } = useTranslation();

  let addressError = '';
  if (errors.deliveryLocationAddressisNotEmpty) {
    addressError = t('This field is required');
  } else if (errorCode === 4002) {
    addressError = t('Please enter street number and city');
  } else if (errorCode === 4003 || errorCode === 4001) {
    addressError = t('Please enter valid address');
  }

  const options = [
    { label: t('Carrier'), value: OrganisationType.Carrier },
    { label: t('Client'), value: OrganisationType.Client },
  ];

  const methods = useForm({
    defaultValues: {
      address: organisation.address || '',
      name: organisation.name || '',
      publicName: organisation.publicName || '',
      shortCode: organisation.shortCode || '',
      type: isClient ? OrganisationType.Client : OrganisationType.Carrier,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    formState: { isDirty },
    handleSubmit,
  } = methods;

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  const getSubmitButtonText = () => {
    if (isEdit) {
      return t('Save Changes');
    }
    if (isClient) {
      return t('Create Client');
    }
    return t('Create Organisation');
  };

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h3 className="mb-5 text-base font-semibold text-primary-dark">
            {t('Basic Information')}
          </h3>
          <div className="mb-8 flex flex-col gap-4">
            <FormInput
              as="input"
              id="name"
              label={t('Legal Name')}
              name="name"
              placeholder={t('Enter name')}
              required
            />
            <FormInput
              as="input"
              id="publicName"
              label={
                <span className="flex items-center">
                  <Tooltip
                    text={t(
                      'Name of the organisation that will be displayed publicly to customers',
                    )}
                  >
                    <Icon
                      className="mr-1 h-3 w-3 text-grey-500 duration-200 hover:text-ui-info-blue"
                      icon="infoFilled"
                    />
                  </Tooltip>
                  {t('Public Name')}
                </span>
              }
              name="publicName"
              placeholder={t('Enter public name')}
              required
            />
            <FormAddressInput
              error={addressError}
              id="organisationAddress"
              label={t('Address')}
              name="address"
              placeholder={t('Enter address')}
              required
            />
          </div>

          <h3 className="mb-5 text-base font-semibold text-primary-dark">
            {t('Setup')}
          </h3>
          <div className="flex flex-col gap-4">
            <ShortcodeInput
              disabled={isEdit}
              disclaimerMessage={t(
                'Shortcode must be exactly 3 characters long.',
              )}
              id="shortCode"
              label={t('Shortcode')}
              maxLength={{
                message: t('Shortcode must be exactly 3 characters long'),
                value: 3,
              }}
              minLength={{
                message: t('Shortcode must be exactly 3 characters long'),
                value: 3,
              }}
              name="shortCode"
              pattern={{
                message: t('Shortcode can only contain uppercase letters'),
                value: /^[A-Z]+$/,
              }}
              placeholder={t('Enter shortcode')}
              required
            />

            <FormSelect
              id="organisation-type"
              label={t('Organisation Type')}
              name="type"
              options={options}
              placeholder={t('Choose Organisation Type')}
              readOnly
              required
            />
          </div>
          <hr className="my-6 text-grey-200" />
          <div className="flex items-center justify-between gap-4">
            <Button
              className="flex-1"
              disabled={isSubmitting}
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={onCancel}
            />
            <Button
              className="flex-1"
              disabled={isSubmitting}
              text={getSubmitButtonText()}
              type="submit"
              variant="solidBlue"
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

OrganisationForm.propTypes = {
  errorCode: PropTypes.number,
  errors: PropTypes.shape({
    deliveryLocationAddressisNotEmpty: PropTypes.string,
  }),
  isClient: PropTypes.bool,
  isEdit: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onIsDirtyChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  organisation: PropTypes.shape({
    address: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    publicName: PropTypes.string,
  }),
};

OrganisationForm.defaultProps = {
  errorCode: null,
  errors: {
    deliveryLocationAddressisNotEmpty: '',
  },
  isClient: false,
  isEdit: false,
  onIsDirtyChange: () => {},
  organisation: { address: '', name: '', type: '' },
};

export default OrganisationForm;
