import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { flushSync } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import FadingOverlay from '../../../components/FadingOverlay';
import Icon from '../../../components/Icon';
import LoadingSpinner from '../../../components/LoadingSpinner';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import TourStatus from '../../../enums/TourStatus';
import useCustomToast from '../../../hooks/useCustomToast';
import useDeliveryUnitStatusTranslations from '../../../hooks/useDeliveryUnitStatusTranslations';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import BulkEditUnitList from './BulkEditUnitList';
import ChangeUnitStatusContext from './ChangeUnitStatusContext';
import DeliveredStatusItem from './DeliveredStatusItem';
import InClarification from './InClarification';
import NotifyCustomer from './NotifyCustomer';
import PostponedStatusItem from './PostponedStatusItem';
import RetryStatusItem from './RetryStatusItem';
import ReturnToClientStatusItem from './ReturnToClientStatusItem';
import SpecialUnitUpdateCases from './SpecialUnitUpdateCases';
import StatusItem from './StatusItem';
import StatusNote from './StatusNote';

const Loader = () => (
  <div className="relative left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2">
    <LoadingSpinner className="text-grey-700" />
  </div>
);

const UnitStatusUpdate = ({
  isBulkUpdate,
  isOpen,
  onBulkUpdateFail,
  onClose,
  showBackButton,
  transparentBackdrop,
  units,
}) => {
  const { toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const queryClient = useQueryClient();
  // ignore missing context if bulk update is not used, because unselect item is not used
  const { unselectItem } = useListSelection({
    ignoreMissingContext: !isBulkUpdate,
  });

  const unitId = units?.[0]?.id;

  const { data: unitFetchData, isFetching: isFetchingUnit } = useQuery(
    [`/units/${unitId}`],
    async () => {
      const response = await fetch(`/units/${unitId}`, {
        method: 'GET',
      });
      return response.json();
    },
    {
      onError: toastFetchError,
      enabled: !!unitId && isOpen,
    },
  );

  const unit = unitFetchData?.data;

  const isUnitRouted = useMemo(() => {
    if (unit?.tours?.length > 0) {
      return unit.tours[0]?.date === unit.date;
    }

    return false;
  }, [unit?.date, unit?.tours]);

  const isTourActiveOrCompleted = useMemo(() => {
    if (unit?.tours?.length > 0) {
      const tour = unit?.tours[0];

      return (
        tour?.status === TourStatus.Active ||
        tour?.status === TourStatus.Completed
      );
    }

    return false;
  }, [unit?.tours]);

  const defaultValues = useMemo(
    () => ({
      status: unit?.status,
      statusNote: unit?.statusNote !== null ? unit?.statusNote : '',
      unitSpecialCase: '',
      notifyCustomer: false,
      deliveryOption: unit?.deliveryOption,
      deliveryOptionNote: unit?.deliveryOptionNote || '',
      failureReason: unit?.failureReason,
      postponeReason: unit?.postponeReason,
      returnReason: unit?.returnReason,
      postponeReasonNote: unit?.reasonNote || '',
    }),
    [
      unit?.deliveryOption,
      unit?.deliveryOptionNote,
      unit?.failureReason,
      unit?.postponeReason,
      unit?.reasonNote,
      unit?.returnReason,
      unit?.status,
      unit?.statusNote,
    ],
  );

  const methods = useForm({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const { formState, getValues, reset, watch } = methods;
  const { isDirty, isValid } = formState;

  useEffect(() => {
    // update default values
    reset({
      status: unit?.status,
      statusNote: unit?.statusNote !== null ? unit?.statusNote : '',
      unitSpecialCase: '',
      notifyCustomer: false,
      deliveryOption: unit?.deliveryOption,
      deliveryOptionNote: unit?.deliveryOptionNote || '',
      failureReason: unit?.failureReason,
      postponeReason: unit?.postponeReason,
      returnReason: unit?.returnReason,
      postponeReasonNote: unit?.reasonNote || '',
    });
  }, [
    reset,
    unit?.deliveryOption,
    unit?.deliveryOptionNote,
    unit?.failureReason,
    unit?.postponeReason,
    unit?.reasonNote,
    unit?.returnReason,
    unit?.status,
    unit?.statusNote,
  ]);

  const newStatus = watch('status');
  const unitSpecialCase = watch('unitSpecialCase');

  const isNewStatusIdenticalToCurrent =
    newStatus === unit?.status && !unitSpecialCase;

  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();

  const { data: fetchedData, isFetching } = useQuery(
    [`/units/${unitId}/status-change-options?bulk=${isBulkUpdate}`],
    async () => {
      const response = await fetch(
        `/units/${unitId}/status-change-options?bulk=${isBulkUpdate}`,
      );
      return response.json();
    },
    { enabled: !!unitId && isOpen },
  );

  const allStatusesListOptions = [
    {
      description: t('Imported into the system'),
      value: DeliveryTaskUnitStatus.Created,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Created],
    },
    {
      description: t('Received in central Hub'),
      value: DeliveryTaskUnitStatus.Arrived,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Arrived],
    },
    {
      description: t('Received in local Hub'),
      value: DeliveryTaskUnitStatus.ArrivedLocal,
      label:
        deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.ArrivedLocal],
    },
    {
      description: t('Did not arrive to Urbify'),
      value: DeliveryTaskUnitStatus.NotArrived,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.NotArrived],
    },
    {
      description: t('Arrived to wrong local Hub'),
      value: DeliveryTaskUnitStatus.Missorted,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Missorted],
    },
    {
      value: DeliveryTaskUnitStatus.Prepared,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Prepared],
      description: t('Ready for driver collection'),
    },
    {
      description: t('Unclear where the Unit is, confirmation needed'),
      value: DeliveryTaskUnitStatus.Misplaced,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Misplaced],
    },
    {
      description: t('Unit was damaged, assessment needed'),
      value: DeliveryTaskUnitStatus.Damaged,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Damaged],
    },
    {
      description: t('In transit to final destination'),
      value: DeliveryTaskUnitStatus.InDelivery,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.InDelivery],
    },
    {
      value: DeliveryTaskUnitStatus.Retry,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Retry],
    },
    {
      value: DeliveryTaskUnitStatus.Postponed,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Postponed],
    },
    {
      value: DeliveryTaskUnitStatus.Lost,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Lost],
      description: t('Missing for over 3 working days; permanently lost'),
    },
    {
      value: DeliveryTaskUnitStatus.Delivered,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Delivered],
    },
    {
      value: DeliveryTaskUnitStatus.ReturnToClient,
      label:
        deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.ReturnToClient],
    },
    {
      value: DeliveryTaskUnitStatus.Stored,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.Stored],
      description: t('Arrived from Tour to central Hub'),
    },
    {
      value: DeliveryTaskUnitStatus.StoredLocal,
      label: deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.StoredLocal],
      description: t('Arrived from Tour to local Hub'),
    },
    {
      value: DeliveryTaskUnitStatus.ShippedToClient,
      label:
        deliveryUnitStatusTranslations[DeliveryTaskUnitStatus.ShippedToClient],
      description: t('Dispatched to line haul'),
    },
  ];

  const availableStatuses = useMemo(
    () => (isFetching ? [] : fetchedData?.data?.statuses || []),
    [isFetching, fetchedData?.data?.statuses],
  );

  const filteredStatusesOptionsList = allStatusesListOptions.filter(
    (status) =>
      availableStatuses.includes(status.value) && unit?.status !== status.value,
  );

  const currentStatusOption = allStatusesListOptions.find(
    (option) => unit?.status === option.value,
  );

  const allOptions = [
    currentStatusOption,
    ...(unit?.status === DeliveryTaskUnitStatus.ShippedToClient
      ? []
      : filteredStatusesOptionsList),
  ];

  const availableDeliveryOptions = useMemo(() => {
    const fetchedOptions = fetchedData?.data?.deliveryOptions || [];
    if (unit?.deliveryOption !== undefined) {
      if (unit?.deliveryOption === null) {
        return [null, ...fetchedOptions];
      }
      return [
        null,
        {
          code: unit?.deliveryOption,
        },
        ...fetchedOptions.filter(
          (option) => option.code !== unit?.deliveryOption,
        ),
      ];
    }

    return [null, ...fetchedOptions];
  }, [fetchedData?.data?.deliveryOptions, unit?.deliveryOption]);

  const availableFailureReasons = useMemo(
    () => fetchedData?.data?.failureReasons || [],
    [fetchedData?.data?.failureReasons],
  );

  const availablePostponeReasons = useMemo(
    () => fetchedData?.data?.postponeReasons || [],
    [fetchedData?.data?.postponeReasons],
  );

  const availableReturnReasons = useMemo(
    () => fetchedData?.data?.returnReasons || [],
    [fetchedData?.data?.returnReasons],
  );

  const contextValue = useMemo(
    () => ({
      defaultValues,
      availableDeliveryOptions,
      availableFailureReasons,
      availablePostponeReasons,
      availableReturnReasons,
      ...((unit?.status === DeliveryTaskUnitStatus.Misplaced ||
        unit?.status === DeliveryTaskUnitStatus.Damaged) && {
        specialUnitStatuses: availableStatuses,
      }),
    }),
    [
      availableDeliveryOptions,
      availableFailureReasons,
      availablePostponeReasons,
      availableReturnReasons,
      availableStatuses,
      defaultValues,
      unit?.status,
    ],
  );

  const mutation = useMutation(
    async () => {
      const values = getValues();

      const status = values.inClarification
        ? DeliveryTaskUnitStatus.InClarification
        : values.status;

      const body = {
        status,
        statusNote: values.statusNote,
        notifyCustomer: values.notifyCustomer,
      };

      if (values.status === DeliveryTaskUnitStatus.Retry) {
        body.failureReason = values.failureReason;
      } else if (values.status === DeliveryTaskUnitStatus.ReturnToClient) {
        body.returnReason = values.returnReason;
      } else if (values.status === DeliveryTaskUnitStatus.Postponed) {
        body.postponeReason = values.postponeReason;
        if (values.postponeReasonNote) {
          body.postponeReasonNote = values.postponeReasonNote;
        }
      } else if (values.status === DeliveryTaskUnitStatus.Delivered) {
        body.deliveryOption = values.deliveryOption;
        body.deliveryOptionNote = values.deliveryOptionNote;
      } else if (values.unitSpecialCase) {
        if (values.status === DeliveryTaskUnitStatus.Damaged) {
          // availableStatuses contains negative outcome (first element), and positive outcome (second element)
          if (values.unitSpecialCase === availableStatuses[0]) {
            [body.returnReason] = availableReturnReasons;
          }
        }
        body.status = values.unitSpecialCase;
      }

      if (isBulkUpdate) {
        const response = await fetch('/units/bulk-status-change', {
          body: {
            ...body,
            unitIds: units.map((u) => u.id),
          },
          method: 'POST',
        });
        return response.json();
      }
      const response = await fetch(`/units/${unit?.id}/status`, {
        body,
        method: 'PATCH',
      });
      return response.json();
    },
    {
      onError: (error) => {
        toastFetchError(error);
      },
      onSuccess: ({ data }) => {
        // flush sync is needed so the drawer closes and therefore triggers...
        // correct isOpen state for the *enabled* option on queries.
        // without the flush sync...
        // the action queryClient.invalidateQueries({queryKey: [`/units/${unit?.id}`]}); would...
        // trigger the invalidate queries in the current drawer
        flushSync(() => {
          onClose();
        });

        if (isBulkUpdate) {
          const failedIds = [];
          units.forEach((u) => {
            unselectItem(u.id);
            if (data?.failedIds?.includes(u?.id)) {
              failedIds.push(u?.code);
            }
          });
          if (failedIds.length > 0) {
            onBulkUpdateFail({
              failedIds,
              successIdsLength: units.length - failedIds.length,
            });
          } else {
            toastSuccess(t('Multiple units’ status updated.'));
          }
        } else {
          toastSuccess(`${t(`Unit`)} ${unit?.code} ${t('status updated')}.`);
        }
        queryClient.invalidateQueries({
          queryKey: ['/units'],
        });
        queryClient.invalidateQueries({
          queryKey: [`/units/${unit?.id}`],
        });
        queryClient.invalidateQueries({
          queryKey: ['/tasks'],
        });
        unit?.tours?.forEach((tour) => {
          queryClient.invalidateQueries({
            queryKey: [`/tour-stops/${tour.tourStop.id}`],
          });
        });
      },
    },
  );

  return (
    <ChangeUnitStatusContext.Provider value={contextValue}>
      <FormProvider {...methods}>
        <Drawer
          showBackButton={showBackButton}
          transparentBackdrop={transparentBackdrop}
          body={
            isFetchingUnit ? (
              <Loader />
            ) : (
              <div className="flex flex-col gap-4 divide-y divide-grey-200">
                {isBulkUpdate ? (
                  <BulkEditUnitList />
                ) : (
                  <div className="flex justify-between text-sm">
                    <div className="flex items-center gap-3">
                      <Icon className="h-5 w-5 text-grey-700" icon="parcel" />
                      <span>{t('Unit code')}</span>
                    </div>
                    <span className="break-all font-medium">{unit?.code}</span>
                  </div>
                )}
                {unit?.status === DeliveryTaskUnitStatus.Misplaced ||
                unit?.status === DeliveryTaskUnitStatus.Damaged ? (
                  <SpecialUnitUpdateCases unit={unit} />
                ) : (
                  <>
                    <div className="pt-4">
                      <InClarification />
                    </div>
                    <div className="flex flex-col gap-4 pt-4">
                      {newStatus !== DeliveryTaskUnitStatus.InClarification && (
                        <>
                          <div className="flex flex-col gap-3">
                            <div className="text-sm text-primary-dark">
                              {t('Select status')}
                            </div>

                            {unit?.attempt === 2 &&
                              (unit?.status ===
                                DeliveryTaskUnitStatus.InDelivery ||
                                unit?.status ===
                                  DeliveryTaskUnitStatus.Arrived ||
                                unit?.status ===
                                  DeliveryTaskUnitStatus.ArrivedLocal ||
                                unit?.status ===
                                  DeliveryTaskUnitStatus.Prepared ||
                                unit?.status ===
                                  DeliveryTaskUnitStatus.Postponed) && (
                                <Alert
                                  message={t(
                                    'Unit is on second execution attempt',
                                  )}
                                  variant="warningOrange"
                                />
                              )}

                            <div
                              className={cn(
                                'flex flex-col gap-1',
                                newStatus ===
                                  DeliveryTaskUnitStatus.InClarification &&
                                  'opacity-40 pointer-events-none',
                              )}
                            >
                              {isFetching ? (
                                <FadingOverlay>
                                  <div className="flex flex-col gap-4 leading-none">
                                    <Skeleton height={48} />
                                    <Skeleton height={48} />
                                  </div>
                                </FadingOverlay>
                              ) : (
                                allOptions.map((option) => {
                                  if (!option) {
                                    return null;
                                  }

                                  if (
                                    option.value ===
                                    DeliveryTaskUnitStatus.Retry
                                  ) {
                                    return (
                                      <RetryStatusItem
                                        key={option.value}
                                        isCurrent={
                                          unit?.status === option.value
                                        }
                                        item={option}
                                        isUnitRouted={isUnitRouted}
                                        currentUnitStatus={unit?.status}
                                        isTourActiveOrCompleted={
                                          isTourActiveOrCompleted
                                        }
                                      />
                                    );
                                  }

                                  if (
                                    option.value ===
                                    DeliveryTaskUnitStatus.ReturnToClient
                                  ) {
                                    return (
                                      <ReturnToClientStatusItem
                                        key={option.value}
                                        isCurrent={
                                          unit?.status === option.value
                                        }
                                        item={option}
                                      />
                                    );
                                  }

                                  if (
                                    option.value ===
                                    DeliveryTaskUnitStatus.Delivered
                                  ) {
                                    return (
                                      <DeliveredStatusItem
                                        key={option.value}
                                        isCurrent={
                                          unit?.status === option.value
                                        }
                                        item={option}
                                      />
                                    );
                                  }

                                  if (
                                    option.value ===
                                    DeliveryTaskUnitStatus.Postponed
                                  ) {
                                    return (
                                      <PostponedStatusItem
                                        key={option.value}
                                        isCurrent={
                                          unit?.status === option.value
                                        }
                                        item={option}
                                        isUnitRouted={isUnitRouted}
                                        currentUnitStatus={unit?.status}
                                        isTourActiveOrCompleted={
                                          isTourActiveOrCompleted
                                        }
                                      />
                                    );
                                  }

                                  return (
                                    <StatusItem
                                      key={option.value}
                                      item={option}
                                      isCurrent={unit?.status === option.value}
                                      isUnitRouted={isUnitRouted}
                                      currentUnitStatus={unit?.status}
                                      isTourActiveOrCompleted={
                                        isTourActiveOrCompleted
                                      }
                                    />
                                  );
                                })
                              )}
                            </div>
                          </div>
                          <hr className="border-grey-200" />
                        </>
                      )}

                      <StatusNote />
                    </div>
                  </>
                )}
              </div>
            )
          }
          footer={
            !isFetchingUnit && (
              <div className="flex flex-col gap-4">
                {unit?.attempt === 2 &&
                  newStatus === DeliveryTaskUnitStatus.Retry &&
                  isDirty && (
                    <Alert
                      fontWeight="normal"
                      message={t(
                        'Since this was second execution attempt, this action will trigger "Return to Client" status',
                      )}
                      variant="info"
                    />
                  )}
                <NotifyCustomer
                  notifyCustomerStatuses={
                    fetchedData?.data?.notifyCustomerStatuses || []
                  }
                  isDisabled={isNewStatusIdenticalToCurrent}
                />
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <Button
                    className="order-last sm:order-none"
                    data-test="modal-button-cancel"
                    text={t('Cancel')}
                    variant="outlineBlack"
                    onClick={onClose}
                  />
                  <Button
                    data-test="modal-button-action"
                    disabled={mutation.isLoading || !isDirty || !isValid}
                    text={t('Save Changes')}
                    variant="solidBlue"
                    onClick={mutation.mutate}
                  />
                </div>
              </div>
            )
          }
          header={
            <span className="flex items-center gap-2">
              {t('Update Unit Status')}
            </span>
          }
          isOpen={isOpen}
          onClose={() => {
            reset();
            onClose();
          }}
        />
      </FormProvider>
    </ChangeUnitStatusContext.Provider>
  );
};

UnitStatusUpdate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      attempt: PropTypes.number,
      id: PropTypes.string,
      code: PropTypes.string,
      status: PropTypes.oneOf(Object.values(DeliveryTaskUnitStatus)),
      unitsInTask: PropTypes.number,
      latestTour: PropTypes.shape({}),
      deliveryOption: PropTypes.string,
      deliveryOptionNote: PropTypes.string,
      failureReason: PropTypes.string,
      postponeReason: PropTypes.string,
      postponeReasonNote: PropTypes.string,
    }),
  ),
  transparentBackdrop: PropTypes.bool,
  showBackButton: PropTypes.bool,
  isBulkUpdate: PropTypes.bool,
  onBulkUpdateFail: PropTypes.func,
};

UnitStatusUpdate.defaultProps = {
  units: [],
  transparentBackdrop: false,
  showBackButton: false,
  isBulkUpdate: false,
  onBulkUpdateFail: undefined,
};

export default UnitStatusUpdate;
