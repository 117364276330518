import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BadgeIndicator from '../../../components/BadgeIndicator';
import Dropdown from '../../../components/Dropdown/Dropdown';
import IconButton from '../../../components/IconButton';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import TasksUnitsHelper from '../../../helpers/TasksUnitsHelper';
import useIsStuck from '../../../hooks/useIsStuck';
import useUnitsPageSearchParams from '../../../hooks/useUnitsPageSearchParams';
import useResourceQuery from '../../../lib/api/hooks/useResourceQuery';
import Resource from '../../../lib/api/Resource';
import FloatingContainerProvider from '../../../providers/FloatingContainerProvider';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import { useTitlebarHeight } from '../../../providers/TitlebarHeightProvider';
import MobileListVisibleContent from '../../shared/MobileListVisibleContent';
import RoutingCycleInfo from '../../shared/RoutingCycleInfo';
import TasksViewToggle from '../../shared/TasksViewToggle';
import UnitBulkEdit from '../UnitBulkEdit';
import UnitsCardList from '../UnitsCardList';
import UnitsFiltersDrawer from '../UnitsFiltersDrawer';
import UnitsSortDropdown from '../UnitsSortDropdown';

const itemsPerPage = 15;

const UnitsPageMobile = () => {
  const { t } = useTranslation();

  const {
    allParams,
    dateQuery,
    isFilterApplied,
    searchParams,
    setSearchParams,
    unitStatusQuery,
  } = useUnitsPageSearchParams();
  const { totalTitlebarHeight } = useTitlebarHeight();
  const stickyElementRef = useRef(null);
  const isStuck = useIsStuck(stickyElementRef);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const { enableSelection, isSelectionVisible, setIsSelectionDisabled } =
    useListSelection();

  const isBulkFiltersApplied = Boolean(dateQuery) && Boolean(unitStatusQuery);

  useEffect(() => {
    if (isBulkFiltersApplied) {
      setIsSelectionDisabled(false);
    } else {
      setIsSelectionDisabled(true);
    }
  }, [isBulkFiltersApplied, setIsSelectionDisabled]);

  const { queryKey } = useResourceQuery({
    itemsPerPage,
    resourceUrl: '/units',
    params: allParams,
  });

  const { data } = useQuery(queryKey);

  const visiblePageContentRef = useRef(null);

  const unitStatuses = TasksUnitsHelper.getUniqueUnitStatuses(data?.data || []);

  return (
    <Page>
      <UnitsFiltersDrawer
        onClose={() => setIsFilterDrawerOpen(false)}
        isOpen={isFilterDrawerOpen}
      />
      <Titlebar
        textPrimary={t('Unit view')}
        titleRightContent={<TasksViewToggle />}
        showTitleRightContentInCollapse={false}
      />
      {!isSelectionVisible && (
        <div className="bg-white p-4 pb-1">
          <RoutingCycleInfo />
        </div>
      )}
      <div
        ref={stickyElementRef}
        className={cn(
          'bg-white px-4 pb-2 pt-3 sticky z-10 border-b transition-[padding]',
          isStuck ? 'border-grey-200' : 'border-transparent',
        )}
        style={{
          top: `calc(var(--topbar-height) + ${totalTitlebarHeight}px)`,
        }}
      >
        {isSelectionVisible && (
          <div className="mb-4 mt-1">
            <UnitBulkEdit isBulkFiltersApplied={isBulkFiltersApplied} />
          </div>
        )}
        <div className="flex gap-2 justify-between">
          <div className="flex flex-col flex-grow-0 overflow-hidden">
            <UnitsSortDropdown />
            <div className="text-sm pt-3">
              <span className="text-grey-700">{t('Units')}:</span>{' '}
              <span className="font-medium">{data?.count || 0}</span>
            </div>
          </div>
          <div className="flex gap-2">
            <BadgeIndicator variant="warning" isVisible={isFilterApplied}>
              <IconButton
                onClick={() => setIsFilterDrawerOpen(true)}
                iconSize="s"
                icon="filter"
                variant="outlineBlack"
              />
            </BadgeIndicator>
            <Dropdown
              className="h-min"
              menu={[
                {
                  icon: 'edit',
                  iconClassName: 'text-grey-700',
                  onClick: () => {
                    enableSelection();
                    if (!unitStatusQuery) {
                      if (unitStatuses.length === 1) {
                        searchParams.set('status', data.data[0].status);
                      } else {
                        searchParams.delete('progress');
                        searchParams.delete('status');
                      }
                      setSearchParams(searchParams);
                    }
                  },
                  text: t('Bulk edit mode'),
                },
              ]}
              isDisabled={isSelectionVisible}
            >
              <IconButton
                iconSize="s"
                icon="menu"
                variant="outlineBlack"
                disabled={isSelectionVisible}
              />
            </Dropdown>
          </div>
        </div>
      </div>
      <Page.Content variant="grey">
        <FloatingContainerProvider
          floatingContainer={visiblePageContentRef?.current}
        >
          <Resource
            itemsPerPage={itemsPerPage}
            showPageSizePicker={false}
            params={allParams}
            resourceUrl="/units"
            socketEntityName="unit"
          >
            <UnitsCardList />
          </Resource>
          <MobileListVisibleContent
            ref={visiblePageContentRef}
            stickyElementRef={stickyElementRef}
          />
        </FloatingContainerProvider>
      </Page.Content>
    </Page>
  );
};

export default UnitsPageMobile;
