import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LocalStorage from '../../../enums/LocalStorage';
import TasksViewType from '../../../enums/TasksViewType';
import UserRole from '../../../enums/UserRole';
import LogoutConfirmation from '../../../features/shared/LogoutConfirmation/LogoutConfirmation';
import LocalStorageHelper from '../../../helpers/LocalStorageHelper';
import useLogout from '../../../hooks/useLogout';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import {
  useSocketIOChannel,
  useSocketListen,
} from '../../../lib/api/hooks/useSocket';
import Allow from '../../../lib/rbac/Allow';
import { useUser } from '../../../providers/UserProvider';
import MenuItem from './MenuItem';

const Menu = (props) => {
  const { hideTextOnLg, onItemClick, showUserItems } = props;
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();

  const [activityCount, setActivityCount] = useState();
  const [invalidLocationsCount, setInvalidLocationsCount] = useState();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { t } = useTranslation();
  const logout = useLogout();

  const preferedTasksView = LocalStorageHelper.getItem(
    LocalStorage.TasksPageViewPreference,
  );

  const { isGroceries, isUserValid, user } = useUser();
  const loggedInUserRole = useMemo(() => user?.role || '', [user]);

  const isActivityCountEnabled =
    (isUserValid && loggedInUserRole === UserRole.OperationsManager) ||
    (loggedInUserRole === UserRole.HubManager && !isGroceries);

  const isInvalidLocationsCountEnabled =
    (isUserValid && loggedInUserRole === UserRole.OperationsManager) ||
    (loggedInUserRole === UserRole.Admin && !isGroceries);

  const socketChannelActivityConfig = useMemo(
    () => ({
      entity: 'activity',
      id: null,
    }),
    [],
  );
  useSocketIOChannel(socketChannelActivityConfig);

  const socketChannelUnitConfig = useMemo(
    () => ({
      entity: 'unit',
      id: null,
    }),
    [],
  );
  useSocketIOChannel(socketChannelUnitConfig);

  useSocketListen(
    'activity',
    (payload) => {
      if (
        isActivityCountEnabled &&
        payload.type === 'entityCountChanged' &&
        payload?.count !== undefined
      ) {
        setActivityCount(payload.count);
      }
    },
    false,
  );

  useSocketListen(
    'unit',
    (payload) => {
      if (
        isInvalidLocationsCountEnabled &&
        payload.type === 'entityCountChanged' &&
        payload?.count !== undefined
      ) {
        setInvalidLocationsCount(payload.count);
      }
    },
    false,
  );

  useQuery(
    ['/activity/count'],
    async () => {
      const response = await fetch('/activity/count', {
        method: 'GET',
      });
      return response.json();
    },
    {
      enabled: isActivityCountEnabled,
      onError: (error) => toastFetchError(error),
      onSuccess: (response) => {
        setActivityCount(response?.data?.activityCount);
      },
    },
  );

  useQuery(
    ['/tasks/invalid-address-count'],
    async () => {
      const response = await fetch('/tasks/invalid-address-count', {
        method: 'GET',
      });
      return response.json();
    },
    {
      enabled: isInvalidLocationsCountEnabled,
      onError: (error) => toastFetchError(error),
      onSuccess: (response) => {
        setInvalidLocationsCount(response?.data?.count);
      },
    },
  );

  const todayFormatted = moment().format('YYYY-MM-DD');

  return (
    <>
      <LogoutConfirmation
        isOpen={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        onConfirm={() => logout()}
      />

      {isGroceries ? (
        <div className="flex flex-1 flex-col p-0" id="menu-bar">
          <div>
            <Allow roles={[UserRole.OperationsManager, UserRole.HubManager]}>
              <MenuItem
                badgeNumber={activityCount}
                icon="pulse"
                name={t('Activity')}
                to="/activity"
                variant="warning"
                onClick={onItemClick}
              />
            </Allow>
            <Allow
              roles={[
                UserRole.Admin,
                UserRole.OperationsManager,
                UserRole.HubManager,
                UserRole.Customer,
              ]}
            >
              <MenuItem
                icon="timeline"
                name={t('Tours')}
                to={`/grocery-tours?page=1&date=${todayFormatted}`}
                onClick={onItemClick}
              />
            </Allow>

            <Allow
              roles={[
                UserRole.Admin,
                UserRole.OperationsManager,
                UserRole.HubManager,
              ]}
            >
              <MenuItem
                icon="domain"
                name={t('Organisation')}
                to="/my-organisation"
                onClick={onItemClick}
              />
            </Allow>
          </div>

          {showUserItems && (
            <div>
              <hr className="mx-3 my-4 h-px text-grey-300" />
              <MenuItem
                icon="userOutlined"
                name={t('My Account')}
                to="/my-account"
                onClick={onItemClick}
              />
              <MenuItem
                icon="power"
                name={t('Sign out')}
                onClick={() => setShowLogoutModal(true)}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-1 flex-col p-0" id="menu-bar">
          <div>
            <Allow roles={[UserRole.Crew]}>
              <MenuItem
                icon="codeScan"
                name={t('Package Scanning')}
                to="/package-scanning"
                onClick={onItemClick}
                hideTextOnLg={hideTextOnLg}
              />
            </Allow>
            <Allow roles={[UserRole.OperationsManager, UserRole.HubManager]}>
              <MenuItem
                badgeNumber={activityCount}
                icon="pulse"
                name={t('Activity')}
                to="/activity"
                variant="warning"
                onClick={onItemClick}
              />
            </Allow>
            <Allow
              roles={[
                UserRole.Admin,
                UserRole.OperationsManager,
                UserRole.HubManager,
              ]}
            >
              <MenuItem
                icon="task"
                badgeNumber={invalidLocationsCount}
                variant="alert"
                name={t('Tasks')}
                to={`${
                  preferedTasksView === TasksViewType.Tasks
                    ? '/tasks'
                    : '/units'
                }?page=1&date=${todayFormatted}`}
                onClick={onItemClick}
              />
            </Allow>
            <Allow
              roles={[
                UserRole.Admin,
                UserRole.OperationsManager,
                UserRole.HubManager,
                UserRole.Customer,
              ]}
            >
              <MenuItem
                icon="timeline"
                name={t('Tours')}
                to={`/tours/fixed?page=1&date=${todayFormatted}`}
                onClick={onItemClick}
              />
            </Allow>
            <Allow roles={[UserRole.Superadmin]}>
              <MenuItem
                icon="domain"
                name={t('Organisations')}
                to="/organisations"
                onClick={onItemClick}
              />
            </Allow>
            <Allow roles={[UserRole.Superadmin]}>
              <MenuItem
                icon="users"
                name={t('Superadmins')}
                to="/superadmins"
                onClick={onItemClick}
              />
            </Allow>
            <Allow
              roles={[
                UserRole.Admin,
                UserRole.OperationsManager,
                UserRole.HubManager,
              ]}
            >
              <MenuItem
                icon="domain"
                name={t('Organisation')}
                to="/my-organisation"
                onClick={onItemClick}
              />
            </Allow>
          </div>

          {showUserItems && (
            <div>
              <hr className="mx-3 my-4 h-px text-grey-300" />
              <MenuItem
                icon="userOutlined"
                name={t('My Account')}
                to="/my-account"
                onClick={onItemClick}
              />
              <MenuItem
                icon="power"
                name={t('Sign out')}
                onClick={() => setShowLogoutModal(true)}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

Menu.propTypes = {
  onItemClick: PropTypes.func,
  showUserItems: PropTypes.bool,
  hideTextOnLg: PropTypes.bool,
};

Menu.defaultProps = {
  onItemClick: () => {},
  showUserItems: true,
  hideTextOnLg: true,
};

export default Menu;
