import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import FormDateRangePicker from '../../../components/FormDateRangePicker';
import FormSelect from '../../../components/FormSelect';
import Icon from '../../../components/Icon';
import InputLayout from '../../../components/InputLayout';
import Modal from '../../../components/Modal';
import RadioGroup from '../../../components/RadioGroup';
import useAsyncFn, { AsyncStatus } from '../../../hooks/useAsyncFn';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';

const fileTypes = [
  {
    label: 'MS Excel (.xlsx)',
    value: 'xlsx',
  },
  {
    label: 'CSV (.csv)',
    value: 'csv',
  },
  {
    label: 'HTML (.html)',
    value: 'html',
  },
];

const DataExportModal = ({ isGroceries, isOpen, onClose: propOnClose }) => {
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { toastError, toastSuccess } = useCustomToast();

  const { data: allClientsQueryData, isLoading: allClientsQueryIsLoading } =
    useQuery(
      ['/clients'],
      async () => {
        const response = await fetch('/clients', {
          method: 'GET',
        });
        return response.json();
      },
      {
        enabled: isOpen,
        onError: (error) => toastFetchError(error),
      },
    );

  const organisations = useMemo(() => {
    if (!allClientsQueryData?.data) {
      return [];
    }
    return allClientsQueryData?.data.map((org) => ({
      label: org.name,
      value: org.id,
    }));
  }, [allClientsQueryData?.data]);

  const methods = useForm({
    defaultValues: {
      dataType: isGroceries ? 'grocery-tours' : 'tours',
      exportType: 'single',
      dateRange: [null, null],
      fileType: 'xlsx',
      organisationId: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = methods;

  const onClose = useCallback(() => {
    propOnClose();
    reset();
  }, [propOnClose, reset]);

  const exportTypeSelected = watch('exportType');

  const dataTypeOptions = useMemo(
    () => [
      {
        label: t('Export Data by Tours'),
        value: 'tours',
      },
      {
        label: t('Export Data by Units'),
        value: 'units',
      },
    ],
    [t],
  );

  const exportTypeOptions = useMemo(
    () => [
      {
        label: t('Single Client'),
        value: 'single',
      },
      {
        label: t('All Clients'),
        value: 'all',
      },
    ],
    [t],
  );

  const { runAsyncFn: download, status: downloadStatus } = useAsyncFn(
    async (values) => {
      try {
        const fromDate = moment(values.dateRange[0]).format('YYYY-MM-DD');
        const toDate = moment(values.dateRange[1]).format('YYYY-MM-DD');
        const params = {
          fileType: values.fileType,
          fromDate,
          toDate,
          ...(values.organisationId && {
            clientId: values.organisationId,
          }),
        };
        const url = `/exports/${values.dataType}`;

        const searchParamsString = new URLSearchParams(params).toString();
        const fullUrl = searchParamsString
          ? `${url}?${searchParamsString}`
          : url;

        const response = await fetch(fullUrl);

        if (response.status === 200 || response.status === 201) {
          onClose();
          toastSuccess(t('Data export started.'));
        } else {
          toastError(t('Unknown error occurred.'));
        }
      } catch (e) {
        toastFetchError(e);
      }
    },
    [fetch, onClose, t, toastError, toastFetchError, toastSuccess],
  );

  return (
    <Modal
      body={
        <FormProvider {...methods}>
          <div className="flex flex-col gap-4">
            <div className="flex items-start gap-2 text-sm text-grey-700">
              <Icon className="h-4 w-4 text-ui-info-blue" icon="infoFilled" />
              <span>
                {t(
                  'Please note that exporting may require some time. Once the data is prepared, link for download will be sent to your designated e-mail address.',
                )}
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <Controller
                control={control}
                name="exportType"
                render={({ field: { onChange, ref, value, ...rest } }) => (
                  <InputLayout
                    error={errors?.exportType && errors?.exportType.exportType}
                    id="export-type"
                    required
                  >
                    <RadioGroup
                      options={exportTypeOptions}
                      value={value}
                      onChange={(newValue) => {
                        if (newValue === 'all') {
                          methods.setValue('organisationId', '');
                        }
                        onChange(newValue);
                      }}
                      {...rest}
                    />
                  </InputLayout>
                )}
              />
              {exportTypeSelected === 'single' && (
                <FormSelect
                  disabled={allClientsQueryIsLoading}
                  id="organisation-select"
                  label={t('Organisation')}
                  name="organisationId"
                  options={organisations}
                  placeholder={t('Select Client Organisation')}
                  required
                />
              )}
            </div>
            <div className="flex flex-col gap-4">
              <FormDateRangePicker
                id="date-range"
                isClearable
                label={t('Date Range')}
                name="dateRange"
                required
              />
              <div className="border border-grey-200" />
              {!isGroceries && (
                <Controller
                  control={control}
                  name="dataType"
                  render={({ field: { onChange, ref, value, ...rest } }) => (
                    <InputLayout
                      error={errors?.dataType && errors?.dataType.dataType}
                      id="data-type"
                      required
                    >
                      <RadioGroup
                        options={dataTypeOptions}
                        value={value}
                        onChange={onChange}
                        {...rest}
                      />
                    </InputLayout>
                  )}
                />
              )}
            </div>
            <FormSelect
              id="file-type"
              label={t('Document Format')}
              name="fileType"
              options={fileTypes}
              required
            />
          </div>
        </FormProvider>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            disabled={
              !methods.formState.isValid ||
              downloadStatus === AsyncStatus.Loading ||
              allClientsQueryIsLoading
            }
            text={t('Generate Report')}
            variant="solidBlue"
            onClick={handleSubmit(download)}
          />
        </div>
      }
      header={
        <span className="flex items-center gap-2">
          <Icon className="h-6 w-6 text-grey-700" icon="dataExport" />
          <span>{t('Data Export')}</span>
        </span>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

DataExportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isGroceries: PropTypes.bool,
};

DataExportModal.defaultProps = {
  isGroceries: false,
};

export default DataExportModal;
