import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import getFullUrl from '../helpers/getFullUrl';
import useFetch from '../lib/api/hooks/useFetch';

const useInfiniteScrollQuery = ({
  itemsPerPage = 50,
  params = {},
  // 30 seconds
  staleTime = 30 * 1000,
  url,
}) => {
  const { fetch } = useFetch();
  const fetchPage = async (fetchParams) => {
    const fullUrl = getFullUrl(url, fetchParams);
    const response = await fetch(fullUrl);
    return response.json();
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [url, itemsPerPage, params],
    queryFn: ({
      pageParam = {
        skip: 0,
        take: itemsPerPage,
      },
    }) =>
      fetchPage({
        skip: pageParam.skip,
        take: pageParam.take,
        ...params,
      }),
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage) {
        return undefined;
      }

      const currentPage = allPages.length;
      const isLastPage = lastPage.data.length < itemsPerPage;

      if (!isLastPage) {
        return {
          take: itemsPerPage,
          skip: currentPage * itemsPerPage,
        };
      }

      return undefined;
    },
    staleTime,
  });

  const flattenedData = useMemo(
    () =>
      data?.pages.reduce(
        (accumulatedOptions, page) => [...accumulatedOptions, ...page.data],
        [],
      ) || [],
    [data?.pages],
  );

  const count = data?.pages[0]?.count || 0;

  const onBottomReached = useCallback(() => {
    if (isFetching || !hasNextPage || flattenedData?.length >= count) {
      return;
    }
    fetchNextPage();
  }, [count, fetchNextPage, flattenedData?.length, hasNextPage, isFetching]);

  return {
    onBottomReached,
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    flattenedData,
    count,
    isLoading,
    isFetchingNextPage,
  };
};

export default useInfiniteScrollQuery;
