import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const MemberStatus = ({ status: propStatus }) => {
  const { t } = useTranslation();

  const renderUserStatus = useCallback(
    (status) => {
      if (status === 'active') {
        return t('Active');
      }
      if (status === 'invited') {
        return t('Invited');
      }
      return '';
    },
    [t],
  );

  return (
    <span
      className={cn(
        'capitalize',
        propStatus === 'active' && 'text-ui-green-dark',
        propStatus === 'invited' && 'text-ui-warning-orange',
      )}
    >
      {renderUserStatus(propStatus)}
    </span>
  );
};

MemberStatus.propTypes = {
  status: PropTypes.string,
};

MemberStatus.defaultProps = {
  status: undefined,
};

export default MemberStatus;
