import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import FormCheckbox from '../../../components/FormCheckbox';
import Switch from '../../../components/Switch';
import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';

const AlternativeDeliveryOptionForm = ({
  deliveryOption,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { descriptionTranslations, nameTranslations } =
    useDeliveryOptionsTranslations();

  const methods = useForm({
    defaultValues: {
      enabled: deliveryOption.enabled,
      requiredConsent: deliveryOption.requiredConsent,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });
  const { control, formState, getValues, handleSubmit } = methods;
  const proofTranslation = {
    signature: t('signature'),
    photo: t('photo'),
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5 divide-y divide-grey-200">
          <div className="flex items-center justify-between">
            <div>{nameTranslations[deliveryOption?.code]}</div>
            <div
              className="flex items-center gap-3"
              data-test="delivery-option-enabled"
              data-test-status={deliveryOption?.enabled}
            >
              <span className="text-sm font-medium">{t('Enable')}</span>
              <Controller
                control={control}
                name="enabled"
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onChange={onChange} />
                )}
              />
            </div>
          </div>
          <div className="pt-3">
            <FormCheckbox
              data-test="recipent-consent-checkbox"
              disabled={!getValues().enabled}
              id="recipent-consent"
              label={t('Require customer consent')}
              name="requiredConsent"
            />
          </div>
          <div className="flex flex-col gap-2 pt-5 text-sm">
            <div>{t('Option details')}</div>
            <div className="flex flex-col gap-3 rounded-md bg-grey-100 p-4">
              <div>
                <span className="text-grey-700">{t('Delivery proof')}: </span>
                {proofTranslation[deliveryOption?.requiredProof]}
              </div>
              <div>
                <span className="text-grey-700">{t('Note field')}: </span>
                <span className="uppercase">
                  {deliveryOption?.requiredNote ? t('on') : t('off')}
                </span>
              </div>
              <div>
                <span className="text-grey-700">
                  {t('Description for customer')}:{' '}
                </span>
                {descriptionTranslations[deliveryOption?.code]}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 grid grid-cols-1 gap-4 border-t-2 border-grey-200 pt-5 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            type="submit"
            data-test="modal-button-action"
            disabled={!formState.isDirty}
            text={t('Save')}
            variant="solidBlue"
          />
        </div>
      </form>
    </FormProvider>
  );
};

AlternativeDeliveryOptionForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  deliveryOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    requiredConsent: PropTypes.bool.isRequired,
    requiredProof: PropTypes.string.isRequired,
    requiredNote: PropTypes.bool,
    enabled: PropTypes.bool,
  }),
};

AlternativeDeliveryOptionForm.defaultProps = {
  deliveryOption: undefined,
};

export default AlternativeDeliveryOptionForm;
