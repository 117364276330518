import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import TabsCard from '../../../components/TabsCard';
import CarrierFeature from '../../../enums/CarrierFeature';
import TourStatus from '../../../enums/TourStatus';
import UserRole from '../../../enums/UserRole';
import getTourDuration from '../../../helpers/getTourDuration';
import metersToKilometers from '../../../helpers/metersToKilometers';
import { SocketHandler } from '../../../lib/api/hooks/useSocket';
import { useIsRole, useUser } from '../../../providers/UserProvider';
import ShiftLabel from '../../shared/ShiftLabel';
import TourPerformance from '../../shared/TourPerformance';
import CardDetailsRow from '../../tours/CardDetailsRow';
import ManualScanning from '../../tours/ManualScanning';
import TourActivity from '../../tours/TourActivity';
import TourCarrier from '../../tours/TourCarrier';
import TourStatusCard from '../../tours/TourStatusCard';
import FixedTourProgress from '../FixedTourProgress';
import TourStops from '../FixedTourStops/TourStops';

const FixedTourDetails = (params) => {
  const { carrierFeatures, tour } = params;
  const { user } = useUser();
  const userRole = user.role;
  const queryClient = useQueryClient();
  const isOperationsManager = useIsRole(UserRole.OperationsManager);
  const isWHM = useIsRole(UserRole.HubManager);

  const { t } = useTranslation();

  const isManualScanningVisible =
    (carrierFeatures?.includes(CarrierFeature.ManualDriverScanning) &&
      tour.status !== TourStatus.Completed &&
      (isWHM || isOperationsManager)) ||
    (tour.status === TourStatus.Completed && tour.manualScanning === true);

  const onTourSocketEvent = useCallback(() => {
    queryClient.invalidateQueries([`/tours/${tour.id}`]);
  }, [queryClient, tour.id]);

  return (
    <>
      <SocketHandler entity="tour" id={tour.id} onEvent={onTourSocketEvent} />
      <div className="grid gap-4 xl:grid-cols-2">
        <div className="flex flex-col gap-4">
          <TourStatusCard tour={tour} />
          <FixedTourProgress tour={tour} />
          <TabsCard
            tabs={[
              {
                content: <TourStops tour={tour} tourId={tour.id} />,
                dataTest: 'delivery-stops',
                label: t('stops'),
              },
              {
                content: (
                  <div className="divide-y divide-grey-200 px-4 pb-4">
                    <CardDetailsRow
                      icon="date"
                      label={t('Date')}
                      value={moment(tour.date).format('DD.MM.YYYY')}
                    />

                    <CardDetailsRow
                      icon="startTime"
                      label={t('Start Time')}
                      value={`${moment(tour.shiftStart).format('HH:mm')}`}
                    />
                    <CardDetailsRow
                      icon="warehouse"
                      label={t('Hub')}
                      value={
                        <>
                          {tour.hub?.name || 'N/A'}
                          <br />
                          <span>{tour.hub?.address}</span>
                        </>
                      }
                    />
                    <CardDetailsRow
                      icon="tourStop"
                      label={t('Stops')}
                      value={tour.totalStops || 'N/A'}
                    />
                    <CardDetailsRow
                      icon="distance"
                      label={t('Drive Distance')}
                      value={
                        tour.distance
                          ? `${metersToKilometers(tour.distance)} km`
                          : 'N/A'
                      }
                    />
                    <CardDetailsRow
                      icon="history"
                      label={t('Drive Duration')}
                      value={getTourDuration(tour)}
                    />
                    <CardDetailsRow
                      icon="parcel"
                      label={t('Unit Count')}
                      value={tour.unitsCount || 'N/A'}
                    />
                  </div>
                ),
                dataTest: 'tour-details',
                label: t('Details'),
              },
            ]}
          />
        </div>

        <div className="flex flex-col gap-4 overflow-hidden print:hidden">
          <div className="flex flex-col gap-4 rounded-md bg-white p-4 shadow-elevation-100">
            <div className="text-sm uppercase text-grey-900">{t('Shift')}</div>
            <div className="flex items-center gap-2">
              <ShiftLabel shiftNumber={tour.shift} />
              <span>
                {moment(tour.shiftStart).format('HH:mm')} -{' '}
                {moment(tour.shiftEnd).format('HH:mm')}
              </span>
            </div>
          </div>
          <TourPerformance rating={tour.rating} score={tour.score} />

          <div className="flex flex-col gap-4 rounded-md bg-white p-4 shadow-elevation-100">
            <div className="text-sm uppercase text-grey-900">
              {t('Carrier')}
            </div>
            <TourCarrier userRole={userRole} tour={tour} />
          </div>

          {isManualScanningVisible && (
            <div className="rounded-md bg-white p-4 shadow-elevation-100">
              <ManualScanning
                isEnabled={tour.manualScanning}
                tourId={tour.id}
              />
            </div>
          )}

          <div
            className="flex flex-col gap-4 rounded-md bg-white p-4 shadow-elevation-100"
            data-test="tour-activity-card"
          >
            <div className="text-sm uppercase text-grey-900">
              {t('Activity Feed')}
            </div>
            <TourActivity tourId={tour.id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FixedTourDetails;
