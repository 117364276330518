import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Icon from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import Breakpoint from '../../../enums/Breakpoint';
import UnitSortType from '../../../enums/UnitSortType';
import useUnitsPageSearchParams from '../../../hooks/useUnitsPageSearchParams';
import useWindowSize from '../../../hooks/useWindowSize';

const UnitsSortDropdown = () => {
  const { currentSortType, dateQuery, searchParams, setSearchParams } =
    useUnitsPageSearchParams();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;

  const latestExecutionDateText = t('Latest execution date');
  const latestStatusChangeText = t('Latest status change');
  const earliestImportTimeText = t('Earliest import time');
  const currentSortTypeText = useMemo(() => {
    switch (currentSortType) {
      case UnitSortType.LatestExecutionDate:
        return latestExecutionDateText;
      case UnitSortType.LatestStatusChange:
        return latestStatusChangeText;
      case UnitSortType.EarliestImport:
        return earliestImportTimeText;
      default:
        return '';
    }
  }, [
    currentSortType,
    earliestImportTimeText,
    latestExecutionDateText,
    latestStatusChangeText,
  ]);

  const isEarliestImportDisabled = !dateQuery;

  useEffect(() => {
    if (!dateQuery && currentSortType === UnitSortType.EarliestImport) {
      searchParams.delete('sortType');
      setSearchParams(searchParams);
    }
  }, [currentSortType, dateQuery, searchParams, setSearchParams]);

  const menuItems = useMemo(
    () => [
      {
        onClick: () => {
          searchParams.set('sortType', UnitSortType.LatestExecutionDate);
          setSearchParams(searchParams);
        },
        text: latestExecutionDateText,
        isActive: currentSortType === UnitSortType.LatestExecutionDate,
      },
      {
        onClick: () => {
          searchParams.set('sortType', UnitSortType.EarliestImport);
          setSearchParams(searchParams);
        },
        isActive: currentSortType === UnitSortType.EarliestImport,
        isDisabled: isEarliestImportDisabled,
        textClassName: 'w-full',
        key: earliestImportTimeText,
        text: (
          <Tooltip
            hidden={!isEarliestImportDisabled}
            text={t('Date filter required')}
          >
            <div className="flex items-center w-full justify-between flex-1 gap-1">
              <span>{earliestImportTimeText}</span>
              {isEarliestImportDisabled && (
                <Icon icon="infoFilled" className="h-4 w-4 text-grey-700" />
              )}
            </div>
          </Tooltip>
        ),
      },
      {
        onClick: () => {
          searchParams.set('sortType', UnitSortType.LatestStatusChange);
          setSearchParams(searchParams);
        },
        text: latestStatusChangeText,
        isActive: currentSortType === UnitSortType.LatestStatusChange,
      },
    ],
    [
      currentSortType,
      earliestImportTimeText,
      isEarliestImportDisabled,
      latestExecutionDateText,
      latestStatusChangeText,
      searchParams,
      setSearchParams,
      t,
    ],
  );

  return (
    <Dropdown menu={menuItems} placement="bottom-end">
      <Button
        size={isMobile ? 'm' : 's'}
        icon="sort"
        isFullWidth
        variant="outlineBlack"
        text={currentSortTypeText}
      />
    </Dropdown>
  );
};

export default UnitsSortDropdown;
